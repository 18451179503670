import * as React from 'react';
// import { Button, Segment, Popup, Label, Dropdown, Icon } from 'semantic-ui-react'

import {
  Button,
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
  Tag,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Badge,
} from '@chakra-ui/react';

import {
  FaEdit,
  FaEllipsisV,
  FaCheckSquare,
  FaUpload,
  FaRegEnvelope,
  FaRegCopy,
  FaPrint,
  FaExclamationTriangle,
} from 'react-icons/fa';

import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import { humanize } from 'underscore.string';
import {
  useList,
  ListPage,
  GenericFilter,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';
import { useSelector } from 'react-redux';

import type { ServiceTypes, ModelTypes } from '@inkcloud/icapi-types';
// import { ReceivedModal } from './ReceivedModal'
// import { SendModal } from './SendModal'
// import DuplicateModal from './DuplicateModal'

import { PrintTypes } from '../../common/PrintModal';

import { IndexWrappedProps } from '../../bootstrap/IndexWrapper';

import { connectOrderItemModals } from '../../common/OrderItemCard2';

import Feathers from '../../bootstrap/feathers';
import AttachDocModal from './AttachDocModal';

type ICEntity<T extends keyof ServiceTypes> = Awaited<ReturnType<ServiceTypes[T]['get']>>;
// type Vendor = ICEntity<'production/vendors'>  TODO
type Vendor = {
  _id: string;
  companyName: string;
};
type PurchaseOrder = ICEntity<'purchase-orders'> & { vendor: Vendor };

const { useState } = React;

interface ListProps {}

const serviceName = '/vendor-billing-reconcillations';
const pathPrefix = '/supplier-billing-reconciliation';

function statusMapper(status: string) {
  if (status === 'awaiting-supplier-response') {
    return 'Awaiting Response';
  }

  if (status === 'pending-review') {
    return 'Submitted';
  }

  return status;
}

const statusToColor = (status: string) => {
  switch (status) {
    case 'awaiting-supplier-response':
      return 'yellow';
    case 'pending-review':
    case 'complete':
      return 'green';
    default:
      return 'gray';
  }
};

const filterQueryMap = (values) => basicQueryMap(values, {});

const statusOptions = [
  { label: 'Draft', value: 'draft' },
  { label: 'Pending Approval', value: 'pending-approval' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Open', value: 'open' },
  { label: 'Partial Received', value: 'partial-received' },
  { label: 'Received', value: 'received' },
];

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Supplier',
      value: 'vendor',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [],
      asyncValueOptions: async () =>
        Feathers.service('production/vendors')
          .find({
            query: {
              $sort: { companyName: 1 },
              $np: 1,
            },
          })
          .then((results) => results.map((r) => ({ value: r._id, label: r.companyName }))),
      type: 'select',
    },
    {
      label: 'Status',
      value: 'status',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: statusOptions,
      type: 'select',
    },
    {
      label: 'Is Approved',
      value: 'isApproved',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { value: true, label: 'True' },
        { value: 'false', label: 'False' },
      ],
      type: 'select',
    },
    {
      label: 'Is Sent',
      value: 'isSent',
      operators: generateOperatorOptions([Operator.Is]),
      valueOptions: [
        { value: true, label: 'True' },
        { value: 'false', label: 'False' },
      ],
      type: 'select',
    },
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'ID',
      value: 'humanId',
      operators: generateOperatorOptions([Operator.Is]),
    },
    {
      label: 'Job Number',
      value: 'jobNumber',
      operators: generateOperatorOptions([Operator.Like, Operator.Is]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const PreList: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;
  const authUser = useSelector((state: any) => state.auth.user);
  const isVendor = !!authUser?.vendor;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);

  const [selectedItem, setSelectedItem] = useState<PurchaseOrder>();

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);

  const printHandleOpenOrderLevel = (modaltype: PrintTypes, payload: { _id: string }) => {
    (props as any).printHandleOpen(modaltype, payload);
  };

  const tableDefinition = [
    {
      key: 'humanId',
      label: 'ID',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: 'rangeDescription',
      label: 'Date Range',
      sortable: true,
      cell: (v) => <> {v ?? ''}</>,
    },
    // {
    //   key: '',
    //   label: 'Job Number',
    //   sortable: true,
    //   cell: (v, r: PurchaseOrder) => <> {r?.jobNumber ?? ''}</>,
    // },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      cell: (v, r: PurchaseOrder) => {
        const a = '';
        return (
          <Badge colorScheme={statusToColor(v)} variant="outline">
            {statusMapper(v)}
          </Badge>
        );
      },
    },
    {
      key: '',
      label: '',
      sortable: false,
      cell: (v, r) => {
        const editLink = isVendor ? `${pathPrefix}/${r._id}` : `${pathPrefix}/${r._id}`;
        return (
          <React.Fragment>
            <ButtonGroup spacing={1}>
              {!isVendor && (
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<FaEllipsisV />}
                    variant="outline"
                  />
                </Menu>
              )}
              {r?.status === 'approved-ready-to-bill' &&
                !r?.attachments?.find((a) => a.label === 'Invoice') && (
                  <Button
                    colorScheme="blue"
                    size="xs"
                    onClick={() => {
                      setSelectedItem(r);
                      setIsOpenModal(true);
                    }}
                  >
                    Upload Invoice Document
                  </Button>
                )}{' '}
              <Tooltip
                label={
                  r?.status !== 'billing-complete' && r?.status !== 'approved-ready-to-bill'
                    ? 'Click to review and submit'
                    : 'View Reconciliation'
                }
              >
                <Link to={editLink}>
                  <Button
                    colorScheme={
                      r?.status !== 'billing-complete' && r?.status !== 'approved-ready-to-bill'
                        ? 'blue'
                        : 'gray'
                    }
                    ml={2}
                    size="xs"
                  >
                    {r?.status !== 'billing-complete' && r?.status !== 'approved-ready-to-bill'
                      ? 'Review'
                      : 'View'}
                  </Button>
                </Link>
              </Tooltip>
            </ButtonGroup>
          </React.Fragment>
        );
      },
      textAlign: 'right',
    },
  ];

  const statusFilter = isVendor ? { status: { $ne: 'draft' } } : {};

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['vendor'], ...statusFilter } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const headerActions = (
    <>
      {!isVendor && (
        <Link to={`${pathPrefix}/add`}>
          <Button colorScheme="blue" size="sm">
            New
          </Button>
        </Link>
      )}
    </>
  );

  return (
    <div>
      <ListPage
        header={'Billing Reconciliation'}
        subheader="List"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />
      {isOpenModal && (
        <AttachDocModal
          vendorReconciliationId={selectedItem?._id as string}
          onClose={() => {
            setIsOpenModal(false);
            setSelectedItem(undefined);
          }}
          onReload={() => history.push(`${pathPrefix}/${selectedItem?._id}`)}
        />
      )}
    </div>
  );
};
export const SupplierList = connectOrderItemModals<IndexWrappedProps>(PreList);
