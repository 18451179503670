/* eslint-disable no-undef */
import React, { KeyboardEventHandler, useEffect, useState } from 'react';
import { Alert, Button, Checkbox, FormControl, FormLabel, Stack } from '@chakra-ui/react';
import type { Options } from 'react-select';
import {
  AsyncSelect,
  CreatableSelect,
  GroupBase,
  OptionsOrGroups,
  Select,
} from 'chakra-react-select';
import DatePicker from 'react-datepicker';
import addYears from 'date-fns/addYears';
import { useSelector } from 'react-redux';

import type { ModelTypes } from '@inkcloud/icapi-types';
import { Feathers } from '../../bootstrap/feathers/feathers';
import { countryStateProvinces } from '../../common';

interface IFilterFormProps {
  values: any;
  errorMessage: string;
  isSubmitting: boolean;
  hasTime?: boolean;
  hasTags?: boolean;
  hasHidden?: boolean;
  hasProducts?: boolean;
  hasOperations?: boolean;
  hasMicrosites?: boolean;
  hasMicrositeUsers?: boolean;
  hasStateProvince?: boolean;
  isMultiStateProvince?: boolean;
  isMultiProducts?: boolean;
  isMultiMicrosites?: boolean;
  hasStatus?: boolean;
  isDateRequired?: boolean;
  onChange: (v) => void;
  onReset?: () => void;
  onSubmit?: () => void;
}

async function loadMicrosites() {
  return Feathers.service('/microsites').find({
    query: {
      $select: ['name', 'key'],
      $sort: { name: 1 },
      $np: 1,
    },
  });
}
async function loadProducts(e: string, callback) {
  const res = await Feathers.service('/products-legacy').find({
    query: {
      $select: ['staticName', 'key'],
      $sort: { staticName: 1 },
      $or: [{ staticName: { $LIKE: e } }, { key: { $LIKE: e } }],
    },
  });

  return callback(
    res?.data?.map((r) => ({
      label: `${r?.staticName ? `${r.staticName} - ` : ''} ${r?.key ?? ''} `,
      value: r._id,
    }))
  );
}

const loadMicrositeUsers = (microsite: string) => async (e: string, callback) => {
  const res = await Feathers.service('/microsites/customers').find({
    query: {
      microsite,
      $select: ['firstName', 'lastName'],
      $sort: { lastName: 1 },
      $or: [{ firstName: { $LIKE: e } }, { lastName: { $LIKE: e } }],
    },
  });

  return callback(
    res?.data?.map((r) => ({
      label: `${r?.firstName} ${r.lastName}`,
      value: r._id,
    }))
  );
};

const createOption = (label: string) => ({
  label,
  value: label,
});
export default function FilterForm(props: IFilterFormProps) {
  const {
    values,
    hasTime,
    hasTags,
    hasHidden,
    hasMicrosites,
    hasMicrositeUsers,
    hasProducts,
    hasOperations,
    hasStateProvince,
    isMultiStateProvince,
    isMultiMicrosites,
    isMultiProducts,
    isDateRequired = true,
    hasStatus,
    onChange,
    errorMessage,
    isSubmitting,
    onReset,
    onSubmit,
  } = props;

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: {
        tenant: { internationalization: { locale: string; currency: string; countryCode: string } };
      };
    }) => globals.tenant.internationalization
  );
  const [microsites, setMicrosites] = useState<ModelTypes.Microsites[]>([]);

  const [tag, setTag] = useState('');

  const maxDate = addYears(values?.start, 1);
  const exceedDate = values?.start && values?.end ? values.end > maxDate : false;

  const required = isDateRequired ? values?.start && values?.end : true;

  const isDisabled = !required || isSubmitting || !!exceedDate;

  useEffect(() => {
    if (hasMicrosites) {
      (async () => {
        const res = await loadMicrosites();
        setMicrosites(res?.map((r) => ({ label: r.name, value: r._id, sku: r.key })));
      })();
    }
  }, []);

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!tag) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        onChange({ ...values, tags: [...values.tags, createOption(tag)] });
        setTag('');
        event.preventDefault();
        break;
      default:
    }
  };

  console.log('values', values);
  return (
    <>
      <Stack direction="row" spacing={4}>
        {isDateRequired && (
          <>
            <FormControl>
              <FormLabel>Start</FormLabel>

              <DatePicker
                dateFormat={`MM/dd/yyyy ${hasTime ? 'h:mm a' : ''}`}
                selected={values?.start}
                selectsStart
                startDate={values?.start}
                showTimeSelect={hasTime}
                timeCaption="time"
                timeFormat="h:mm a"
                endDate={values?.end}
                onChange={(date: Date) => onChange({ ...values, start: date })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>End</FormLabel>

              <DatePicker
                dateFormat={`MM/dd/yyyy ${hasTime ? 'h:mm a' : ''}`}
                selected={values?.end}
                selectsStart
                startDate={values?.end}
                showTimeSelect={hasTime}
                timeCaption="time"
                timeFormat="h:mm a"
                minDate={values?.start}
                maxDate={maxDate > new Date() ? new Date() : maxDate}
                onChange={(date: Date) => onChange({ ...values, end: date })}
              />
            </FormControl>
          </>
        )}
        {hasStateProvince && (
          <FormControl>
            <FormLabel>State/Province</FormLabel>
            <Select
              useBasicStyles
              isClearable
              isMulti={isMultiStateProvince}
              isDisabled={isSubmitting}
              value={values?.stateProvince}
              placeholder=""
              options={countryStateProvinces(tenant.countryCode)?.map(
                (r: { text: string; value: string }) => ({ label: r.text, value: r.value })
              )}
              onChange={(e: Options) => onChange({ ...values, stateProvince: e })}
            />
          </FormControl>
        )}
      </Stack>

      <Stack mt={3} direction="row" spacing={4}>
        {hasOperations && (
          <FormControl>
            <FormLabel>Operations</FormLabel>
            <Select
              useBasicStyles
              isClearable
              isDisabled={isSubmitting}
              placeholder=""
              value={values?.operations}
              options={[
                { label: 'Adjustment', value: 'ADJUSTMENT' },
                { label: 'Allocated', value: 'ALLOCATED' },
                { label: 'Fulfilled', value: 'FULFILL' },
                { label: 'Receive', value: 'RECEIVE' },
                { label: 'Return', value: 'RETURN' },
              ]}
              onChange={(e: Options) => onChange({ ...values, operations: e })}
            />
          </FormControl>
        )}
        {hasProducts && (
          <FormControl>
            <FormLabel>Products</FormLabel>
            <AsyncSelect
              useBasicStyles
              isClearable
              isMulti={isMultiProducts}
              isDisabled={isSubmitting}
              placeholder=""
              value={values?.products}
              loadOptions={loadProducts}
              onChange={(e: Options) => onChange({ ...values, products: e })}
              noOptionsMessage={(v) =>
                !v.inputValue ? 'Start typing product to search...' : 'No options'
              }
            />
          </FormControl>
        )}

        {hasTags && (
          <FormControl>
            <FormLabel>Tags</FormLabel>

            <CreatableSelect
              useBasicStyles
              isClearable
              isMulti
              menuIsOpen={false}
              placeholder=""
              value={values?.tags}
              inputValue={tag}
              onChange={(newValue) => onChange({ ...values, tags: newValue })}
              onInputChange={(newValue) => setTag(newValue)}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        )}
        {hasMicrosites && (
          <FormControl>
            <FormLabel>Microsites</FormLabel>
            <Select
              useBasicStyles
              isClearable
              isMulti={isMultiMicrosites}
              isDisabled={isSubmitting}
              placeholder=""
              value={values?.microsites}
              options={microsites}
              onChange={(e: Options) => onChange({ ...values, microsites: e })}
            />
          </FormControl>
        )}
        {hasStatus && (
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              useBasicStyles
              isDisabled={isSubmitting}
              placeholder=""
              value={values?.status}
              defaultValue={{ label: 'Inactive', value: 'inactive' }}
              options={[
                { label: 'Inactive', value: 'inactive' },
                { label: 'Active', value: 'active' },
              ]}
              onChange={(e: Options) => onChange({ ...values, status: e })}
            />
          </FormControl>
        )}
        {hasMicrositeUsers && values?.microsites?.value ? (
          <FormControl>
            <FormLabel>Users</FormLabel>
            <AsyncSelect
              useBasicStyles
              isClearable
              isMulti={true}
              isDisabled={isSubmitting}
              placeholder=""
              value={values?.products}
              loadOptions={loadMicrositeUsers(values?.microsites?.value)}
              onChange={(e: Options) => onChange({ ...values, micrositeUsers: e })}
              noOptionsMessage={(v) =>
                !v.inputValue ? "Start typing user's name to search..." : 'No options'
              }
            />
          </FormControl>
        ) : null}

        {hasHidden && (
          <FormControl>
            <FormLabel>Include Hidden</FormLabel>
            <Checkbox
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onChange({ ...values, includeHidden: e.target.checked })
              }
            />
          </FormControl>
        )}
      </Stack>
      {exceedDate && (
        <Alert status="warning" mt={3}>
          Maximum date range is 1 year
        </Alert>
      )}
      {errorMessage && (
        <Alert status="error" mt={3}>
          {errorMessage}
        </Alert>
      )}

      {onReset && (
        <Button mt={3} onClick={onReset}>
          Reset
        </Button>
      )}
      {onSubmit && (
        <Button
          colorScheme="blue"
          mt={3}
          isDisabled={isDisabled}
          isLoading={isSubmitting}
          onClick={onSubmit}
        >
          Submit
        </Button>
      )}
    </>
  );
}
