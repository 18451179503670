import * as React from 'react';
import {
  Box,
  Button,
  HStack,
  IconButton,
  Tag,
  Tooltip,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Divider,
} from '@chakra-ui/react';

import { endOfDay } from 'date-fns';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedTime } from 'react-intl';
import {
  useList,
  ListPage,
  generateOperatorOptions,
  Operator,
  basicQueryMap,
} from '@inkcloud/components';
import { FaEdit } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import feathers from '../../../bootstrap/feathers';
import ReactDatePicker from 'react-datepicker';

interface ListProps {
  products: { value: string; text: string }[];
  categories: { value: string; text: string }[];
}

const serviceName = 'user-allotments';

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values, {});

  filterRows = {
    ...filterRows,
    micrositeUser: filterRows.micrositeUser?.$elemMatch?.value,
    allotmentPolicy: filterRows.allotmentPolicy?.$elemMatch?.value,
  };

  return filterRows;
};

interface IAppProps {
  filter: any;
  state: any;
  reload: () => null;
}

const MassUpdate: React.FunctionComponent<IAppProps> = (props) => {
  const { filter, state, reload } = props;
  const queryMap = filterQueryMap;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [expDate, setExpDate] = React.useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleSubmit = async () => {
    // submit to backend

    const mappedFilter = queryMap(filter);

    setIsSubmitting(true);
    await feathers.service('user-allotments-mass-update').create({
      filter: mappedFilter,
      values: {
        expiresAt: expDate,
      },
    });
    setIsSubmitting(false);
    reload();
    onClose();
  };

  const mappedFilter = queryMap(filter);

  if (!mappedFilter || Object.values(mappedFilter).filter((v) => v).length === 0) {
    return null;
  }

  return (
    <>
      <Button colorScheme="blue" onClick={onOpen}>
        Mass Update
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>User Allotment Mass Update</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>The following values will be applied to all filtered results</p>

            <Divider my={4} />
            <Box>
              <FormControl>
                <FormLabel>Expiration Date</FormLabel>
                <ReactDatePicker
                  disabled={isSubmitting}
                  value={expDate}
                  placeholderText="Exp Date..."
                  onChange={(e) => setExpDate(e?.toLocaleDateString())}
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
              colorScheme="blue"
              onClick={handleSubmit}
            >
              Apply Mass Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default async function getFilterDefinition(micrositeId: string) {
  const definition = [
    // {
    //   label: 'Name',
    //   value: 'allottmentPolicy.name',
    //   operators: generateOperatorOptions([Operator.Like]),
    // },
    {
      label: 'Amount',
      value: 'amount',
      operators: generateOperatorOptions([
        Operator.Is,
        Operator.IsNot,
        Operator.GreaterThan,
        Operator.LessThan,
      ]),
    },
    {
      label: 'Available',
      value: 'availableAmount',
      operators: generateOperatorOptions([
        Operator.Is,
        Operator.IsNot,
        Operator.GreaterThan,
        Operator.LessThan,
      ]),
    },
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Policy',
      value: 'allotmentPolicy',
      operators: generateOperatorOptions([Operator.Is]),
      asyncValueOptions: async () =>
        feathers
          .service('user-allotment-policies')
          .find({
            query: {
              $np: '1',
              microsite: micrositeId,
              $select: ['_id', 'name'],
              $sort: { name: 1 },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.name ?? ''}`,
            }))
          ),
      type: 'select',
    },

    {
      label: 'Products',
      value: 'products',
      operators: generateOperatorOptions([Operator.Is]),
      asyncValueOptions: async () =>
        feathers
          .service('products-legacy')
          .find({
            query: {
              $np: '1',
              $select: ['_id', 'staticName', 'key'],
              $sort: { name: 1 },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.staticName ?? ''} - ${r?.key ?? ''}`,
            }))
          ),
      type: 'multi-select',
    },
    {
      label: 'MS User',
      value: 'micrositeUser',
      operators: generateOperatorOptions([Operator.Is]),
      asyncValueOptions: async () =>
        feathers
          .service('microsites/customers')
          .find({
            query: {
              $np: '1',
              microsite: micrositeId,
              $sort: { name: 1 },
            },
          })
          .then((results) =>
            results.map((r) => ({
              value: r._id,
              label: `${r?.companyName ?? ''} - ${r?.firstName ?? ''} ${r?.lastName ?? ''}`,
            }))
          ),
      type: 'select',
    },
    {
      label: 'Date:Updated',
      value: 'updatedAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    //
    // {
    //   label: 'Description',
    //   value: 'description',
    //   operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    // },
    {
      label: 'Expires At',
      value: 'expiresAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },

    {
      label: 'Name',
      value: 'name',
      operators: generateOperatorOptions([Operator.Like, Operator.Is, Operator.IsNot]),
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<
  ListProps & RouteComponentProps<{ micrositeId: string }>
> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const pathPrefix = `/settings/microsites/${params.micrositeId}/allotments`;

  const useListValues = useList({
    feathers: [
      'find',
      serviceName,
      [
        {
          query: {
            microsite: params.micrositeId,
            $populate: ['micrositeUser', 'allotmentPolicy', 'allotmentPolicy.products', 'products'],
            $sort: { createdAt: -1 },
          },
        },
      ],
    ],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tenant = useSelector(
    ({
      globals,
    }: {
      globals: { tenant: { internationalization: { currency: string; locale: string } } };
    }) => globals.tenant.internationalization
  );

  const tableDefintion = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => (
        <Tooltip hasArrow aria-label="time" label={<FormattedTime value={v} />}>
          <Box>
            <FormattedDate value={v} />
          </Box>
        </Tooltip>
      ),
    },
    {
      key: '',
      label: 'MS User',
      cell: (v, r) =>
        `${r?.micrositeUser?.companyName ?? ''} - ${r?.micrositeUser?.firstName ?? ''} ${
          r?.micrositeUser?.lastName ?? ''
        }`,
    },
    {
      key: '',
      label: 'Policy',
      cell: (v, r) => r?.allotmentPolicy?.name,
    },
    {
      key: 'products',
      label: 'Products',
      cell: (v, r) => (
        // <HStack spacing={2}>
        //   {r?.allottmentPolicy?.products?.map((p) => (
        //     <Tag colorScheme="gray.50" key={p._id}>{`${p?.staticName ? `${p.staticName} - ` : ''} ${
        //       p?.key ?? ''
        //     }`}</Tag>
        //   ))}
        // </HStack>
        <VStack spacing={1} alignItems="baseline">
          {r?.products?.map((p) => (
            <Box key={p._id}>
              <Tag colorScheme="gray.50" key={p._id}>{`${
                p?.staticName ? `${p.staticName} - ` : ''
              } ${p?.key ?? ''}`}</Tag>
            </Box>
          ))}
        </VStack>
      ),
    },
    {
      key: 'amount',
      label: 'Amount',
      textAlign: 'right',
    },

    {
      key: 'availableAmount',
      label: 'Available',
      textAlign: 'right',
    },
    {
      key: 'expiresAt',
      label: 'Expires',
      cell: (v, r) => (v ? <FormattedDate value={v} /> : null),
    },

    {
      key: '',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Edit Details">
          <Link to={`${pathPrefix}/${r._id}`}>
            <IconButton colorScheme="gray" aria-label="Edit" icon={<FaEdit />} />
          </Link>
        </Tooltip>
      ),
      textAlign: 'right',
    },
  ];

  const headerActions = (
    <>
      <Button onClick={() => history.goBack()}>Back</Button>
      <Link to={`/settings/microsites/${params.micrositeId}/allotment-policies`}>
        <Button colorScheme="blue" mx={2}>
          Policies
        </Button>
      </Link>
      <Link to={`${pathPrefix}/add`}>
        <Button colorScheme="blue">New</Button>
      </Link>
    </>
  );
  return (
    <div>
      <ListPage
        header="Allotments"
        subheader="Microsites"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        headerActions={headerActions}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        getFilterDefinition={() => getFilterDefinition(params.micrositeId)}
        massUpdateComponent={MassUpdate}
      />
    </div>
  );
};
