/* eslint-disable no-nested-ternary */

import React, { useState } from 'react';
import { Badge, Button, Tag, Tooltip, Link as ChakraLink } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  useList,
  ListPage,
  basicQueryMap,
  generateOperatorOptions,
  Operator,
} from '@inkcloud/components';
import feathers, { icapi } from '../../../bootstrap/feathers';
import DateWithHover from '../../../common/DateWithHover';

import AddModal from './AddModal';
import { FaFileDownload } from 'react-icons/fa';

interface ListProps {}

const serviceName = 'printable-combined';
const pathPrefix = '/admin/batch-doc-log';

const filterQueryMap = (values) => basicQueryMap(values, {});

export async function getFilterDefinition() {
  const definition = [
    {
      label: 'Date:Created',
      value: 'createdAt',
      operators: generateOperatorOptions([Operator.Between]),
      type: 'date',
    },
    {
      label: 'Order Item ID',
      value: 'orderItemHumanId',
      operators: generateOperatorOptions([Operator.Like]),
    },
    {
      label: 'Status',
      value: 'status',
      operators: generateOperatorOptions([Operator.Is, Operator.IsNot]),
      valueOptions: [
        { value: 'pending', label: 'pending' },
        { value: 'in-progress', label: 'In Progress' },
        { value: 'complete', label: 'Complete' },
        { value: 'failed', label: 'Failed' },
      ],
      type: 'select',
    },
  ];

  return definition.sort((a: any, b: any) => a.label.localeCompare(b.label));
}

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const useListValues = useList({
    feathers: ['find', serviceName, [{ query: { $populate: ['orderItem', 'productionChannel'] } }]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { createdAt: -1 },
  });

  const tableDefinition = [
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      cell: (v) => v && <DateWithHover value={v} />,
    },
    {
      key: 'documents',
      label: 'Document Count',
      sortable: true,
      cell: (v, r) => r.pdfs?.length,
    },
    // {
    //   key: 'writeTo',
    //   label: 'Write To',
    //   sortable: true,
    //   cell: (v, r) => v,
    // },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      cell: (v, r) => (
        <Badge
          mr={4}
          colorScheme={r.status === 'failed' ? 'red' : r.status === 'complete' ? 'green' : 'yellow'}
        >
          {v}
        </Badge>
      ),
    },
    {
      key: 'productionChannel',
      label: '',
      cell: (v, r) => (
        <Tooltip label="Requeue this transport record" aria-label="Trigger additional Transport">
          <Button
            as={ChakraLink}
            href={r.resultUrl}
            target="_blank"
            size="sm"
            variant={'outline'}
            colorScheme={'blue'}
            leftIcon={<FaFileDownload />}
            // onClick={handleDuplicateTransport(r)}
          >
            Download PDF
          </Button>
        </Tooltip>
      ),
    },
  ];

  const headerActions = (
    <Button colorScheme="blue" size="sm" onClick={() => setIsModalOpen(true)}>
      New
    </Button>
  );

  return (
    <>
      <ListPage
        header={'Batch Document Log'}
        subheader="Log"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefinition}
        withCheckboxes={true}
        headerActions={headerActions}
        getFilterDefinition={getFilterDefinition}
      />

      {isModalOpen && (
        <AddModal onReload={() => useListValues.reload()} onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};
