import * as React from 'react';
import { Button, Popup, Label } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { useList, ListPage, basicQueryMap } from '@inkcloud/components';
import { QuantityPriceModal } from './QuantityPriceModal';
import feathers from './../../../bootstrap/feathers';

interface ListProps {}

const filterQueryMap = (values) => {
  let filterRows = basicQueryMap(values);

  filterRows = {
    ...filterRows,
  };

  return filterRows;
};

const serviceName = 'products-legacy/add-ons';
const pathPrefix = '/products/add-on-pricing';

const { useState } = React;

export const List: React.FunctionComponent<ListProps & RouteComponentProps<any>> = (props) => {
  const {
    match: { params },
    location,
    history,
  } = props;

  const useListValues = useList({
    feathers: ['find', serviceName, [{}]],
    filterMap: filterQueryMap,
    debounce: 150,
    location,
    history,
    sort: { addOnId: 1 },
  });

  const [isQuantityModalOpen, setIsQuantityModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [baseAddOns, setBaseAddOns] = useState<any[]>([]);

  React.useEffect(() => {
    const loadAddOnsBase = async () => {
      const res: any = await feathers.service('products-legacy/add-ons-base').find({
        query: {
          $sort: { name: 1 },
        },
      });
      setBaseAddOns(res);
    };

    loadAddOnsBase();
  }, []);

  const handleQuantityPricesSubmit = async (v: any) => {
    setErrorMessage('');
    try {
      const response = await feathers.service('products-legacy/add-ons').patch(id, {
        ...v,
      });

      setId('');
      setValue('');
      setIsQuantityModalOpen(false);
      useListValues.reload();
    } catch (e) {
      const errMsg =
        e?.code < 500 ? e.message : 'We are experiencing technical difficulties. Please try again';
      setErrorMessage(errMsg);
    }
  };

  const headerActions = (
    <>
      <Link to={`${pathPrefix}/add`}>
        <Button content="New" primary size={'mini'} />
      </Link>
    </>
  );

  const tableDefintion = [
    {
      key: 'addOnId',
      label: 'Add On',
      cell: (v, r) => baseAddOns.find((b: any) => b._id === v)?.name ?? v,
    },
    {
      key: 'turnaround',
      label: 'Turnaround Time',
      cell: (v, r) => v,
    },
    {
      key: 'setup',
      label: 'Setup',
      cell: (v, r) => v,
    },
    {
      key: 'minimum',
      label: 'Minimum',
      cell: (v, r) => v,
    },
    {
      key: 'rate',
      label: 'Rate',
      cell: (v, r) => v,
    },
    {
      key: 'sqFtRate',
      label: 'Rate/SqFt',
      cell: (v, r) => v,
    },
    {
      key: '',
      label: '',
      cell: (v, r) => (
        <>
          <Popup
            content="Set Quantity Price"
            trigger={
              <Button
                size="mini"
                icon="window restore outline"
                onClick={() => {
                  setId(r._id);
                  setValue(r);
                  setIsQuantityModalOpen(true);
                }}
              />
            }
          />

          <Popup
            size="mini"
            content="Edit"
            trigger={
              <Link to={`${pathPrefix}/${r._id}`}>
                <Button size={'mini'} icon={'edit'} />
              </Link>
            }
          />
        </>
      ),
      textAlign: 'right',
    },
  ];

  return (
    <>
      <ListPage
        header="Add Ons"
        subheader="Product"
        useListValues={useListValues}
        history={history}
        pathPrefix={pathPrefix}
        tableDefintion={tableDefintion}
        withCheckboxes={true}
        headerActions={headerActions}
      />

      {isQuantityModalOpen && (
        <QuantityPriceModal
          value={value}
          isOpen={isQuantityModalOpen}
          errorMessage={errorMessage}
          onClose={() => {
            setId('');
            setValue('');
            setIsQuantityModalOpen(false);
          }}
          onSubmit={handleQuantityPricesSubmit}
        />
      )}
    </>
  );
};
