import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Segment, Message } from 'semantic-ui-react';
import { FormControl, FormLabel } from '@chakra-ui/react';

import { PageHeaderRow, PreLoader, useFeathers } from '@inkcloud/components';
import {
  AsyncSelect,
  CreatableSelect,
  GroupBase,
  OptionsOrGroups,
  Select,
} from 'chakra-react-select';

import feathers from '../../../bootstrap/feathers';

interface ISandboxSyncProps {}

const SandboxSync: React.FunctionComponent<ISandboxSyncProps & RouteComponentProps> = (props) => {
  const { history } = props;

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isDone, setIsDone] = React.useState(false);
  const [microsite, setMicrosite] = React.useState<string>(null);

  const [data, reload] = useFeathers(
    'find',
    'microsites',
    [
      {
        query: {
          $np: 1,
          $select: ['name'],
          $sort: { name: 1 },
        },
      },
    ],
    true
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await feathers.service('sandbox-sync').create({
        bundle: 'product',
        microsite: microsite === undefined || microsite === '' ? undefined : microsite,
      });

      setIsDone(true);
    } catch (e) {
      console.error(e);
    }
    setIsSubmitting(false);
    setIsDone(true);
  };

  const micrositeOptions = (data.queryResult as any)?.map((d) => ({ value: d._id, label: d.name }));

  return (
    <div>
      <PageHeaderRow header={'Sandbox Sync'} subheader="Settings" subheaderId={''}>
        <Button content="Back" size={'mini'} onClick={() => history.goBack()} />
      </PageHeaderRow>
      <Segment>
        <p>
          Click below to initiate a sync on product-related from production environment to the
          sandbox environment. It may take up to a minute to sync data to sandbox.
        </p>
        <p>You can optionally select a microsite to only sync data for that microsite.</p>
        <br />
        <br />
        <FormControl>
          <FormLabel>Microsites</FormLabel>
          <Select
            useBasicStyles
            isClearable
            isMulti={false}
            isDisabled={isSubmitting}
            placeholder="Optionally select a microsite..."
            value={micrositeOptions?.find((m) => m.value === microsite)}
            options={micrositeOptions}
            onChange={(e: any) => {
              setMicrosite(e?.value);
            }}
          />
        </FormControl>
        <br />

        {isSubmitting && <PreLoader />}
        {isDone && <Message success content="Sync Requested" />}
        {errorMessage && <Message error content={errorMessage} />}

        <Button
          disabled={isSubmitting || isDone}
          primary
          content="Sync Data"
          size={'mini'}
          onClick={handleSubmit}
        />
      </Segment>
    </div>
  );
};

export default SandboxSync;
