// FORCE RELOAD 2222
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  InjectedFormProps,
  Field,
  reduxForm,
  change,
  formValueSelector,
  FieldArray,
} from 'redux-form';
import { chunk } from 'lodash-es';
import { Link } from 'react-router-dom';
import { slugify } from 'underscore.string';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Message,
  Image,
  Icon,
  Checkbox,
  Segment,
  Table,
  Modal,
} from 'semantic-ui-react';

import { AsyncSelect } from 'chakra-react-select';

import { parseISO } from 'date-fns';
import DatePicker from 'react-datepicker';
import * as CleaveReact from 'cleave.js/react';
import Cleave from 'cleave.js';

import type { ModelTypes } from '@inkcloud/icapi-types';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { EntityDropdownWrapper2 } from '../../EntityDropdown';
import {
  semanticFormDropdownField,
  semanticFormField,
  semanticFormToggleField,
  semanticFormCheckboxGroupField,
  semanticFormRadioGroupField,
  semanticFormCheckboxField,
  semanticFormRadioField,
  validateRequired,
  normalizeBoolean,
  formatBoolean,
  groupBy,
  slugify2 as slugifyKey,
  DownloadZip,
} from '../../../common';
import Feathers from '../../../bootstrap/feathers';
import DropdownTags from '../../../common/DropdownTags';
import { ArtworkVariantUpload } from './ArtworkVariantUpload';
import { FixedArtworkUpload } from './FixedArtworkUpload';

import { ImageUpload } from './stock-product/StockForm/ImageUpload';
import { Card } from './stock-product/StockForm/Card';

import { VariantTable } from './stock-product/StockForm/variants/VariantTable';

import { Composite } from './Composite';

import { PagePublish } from './PagePublish';

type AppProps = {
  isEdit: boolean;
  errorMessage: string;
} & InjectedFormProps;

interface AppState {
  categoryDropdown: any;
  categories: any[];
  attributes: any[];
  designTemplates: any[];
  initialStatuses: any[];
  stockProducts: any[];
  addOns: any[];
  quantities: any[];
  productionChannels: any[];
  suppliers: any[];
  glAccounts: any[];
}

const baseUrl = 'https://storage.googleapis.com/icstorage';

const validate = (values) => {
  let errors = validateRequired(['category'])(values);

  if (values.isArtworkRequired) {
    if (!values.artworkSlots || values.artworkSlots.length === 0) {
      errors.artworkSlots = 'Select one or more artwork sequences';
    }
  }

  // if (values.isShippable) {
  //   const shippableErrors = validateRequired(["weightUnit", "weightBasis"])(
  //     values
  //   );
  //   errors = { ...errors, ...shippableErrors };
  // }

  if (values.isDesignEnabled) {
    const designErrors = validateRequired(['designPrice'])(values);
    errors = { ...errors, ...designErrors };
  }

  return errors;
};

const customFieldOptions = [
  { text: 'Text', value: 'text' },
  { text: 'Select', value: 'select' },
  { text: 'Multi Select', value: 'multi-select' },
  { text: 'Datepicker', value: 'datepicker' },
  { text: 'Checkbox', value: 'checkbox' },
];

const Checkbox2 = (props) => (
  <Form.Field>
    <Checkbox
      label={props.label}
      checked={props.input.checked}
      onChange={(e, data) => props.input.onChange(data.checked)}
    />
  </Form.Field>
);

const renderAsyncSelectField = (props) => {
  // console.log('props', props);

  const { input } = props;

  const normalizeValue = input.value?._id
    ? {
        label: `${input?.value?.staticName ?? ''} - ${input?.value?.key ?? ''}`,
        value: input?.value?._id,
      }
    : input.value;

  return (
    <AsyncSelect
      {...props}
      name={input.name}
      classNamePrefix="product-react-select"
      value={normalizeValue}
      onChange={(e: any) => input.onChange(e)}
      styles={{
        container: (provided, state) => ({
          ...provided,
          flexGrow: 2,
          width: '100%',
          border: '1px solid red',
          padding: 4,
        }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid green',
        }),
        input: (styles) => ({ ...styles, border: '1px solid green' }),
        singleValue: (styles, { data }) => ({
          ...styles,
          border: '1px solid yellow',
        }),
      }}
    />
  );
};

const mapOptionsToValue = (options: any) =>
  options?.map((o) => ({
    label: `${o?.staticName ?? ''} - ${o?.key ?? ''}`,
    value: o?._id,
  }));

const handleLoadProducts: any = async function (e, callback) {
  try {
    const res: any = await Feathers.service('/products-legacy').find({
      query: {
        staticName: { $LIKE: e },
        inventory: true,
        $sort: { createdAt: -1 },
      },
    });

    callback(mapOptionsToValue(res?.data));
  } catch (er) {
    console.error(er);
  }
};

const required = (value) => {
  // console.log({ value });
  if (Array.isArray(value)) {
    return value.length > 0 ? undefined : 'Required';
  }
  return value ? undefined : 'Required';
};

const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number only.' : undefined);

const requiredNumber = (value) =>
  value >= 0 ? (isNaN(Number(value)) ? 'Must be a number only.' : undefined) : 'Required';

const asyncValidate = (values, dispatch, props: AppProps, blurredField) => {
  if (blurredField === 'key') {
    if (!props.isEdit || (props.initialValues && props.initialValues.key !== values.key.trim())) {
      return Feathers.service<any>('/products-legacy')
        .find({ query: { key: values.key.trim() } })
        .then((results: any) => {
          if (results.data.length > 0) {
            throw { key: 'That key is taken' };
          }
        });
    }
  }
  return Promise.resolve();
};

export const currencyFormat = (value) => {
  const input = window.document.createElement('input');
  input.value = value;

  const newCleave = new Cleave(input, {
    numeral: true,
    rawValueTrimPrefix: true,
    numeralPositiveOnly: true,
    numeralThousandsGroupStyle: 'thousand',
    prefix: '$',
  });

  return newCleave.getRawValue();
};

type T = ModelTypes.ProductAttributeLegacy & {
  entityAttribute: ModelTypes.ProductAttributeLegacy['entityAttribute'] & {
    _id: string;
  };
};

const ProductForm = (props: AppProps) => {
  // class ProductForm extends React.Component<AppProps, AppState> {
  //   constructor(props) {
  //     super(props);
  //     state = {
  //       categoryDropdown: null,
  //       categories: [],
  //       attributes: [],
  //       designTemplates: [],
  //       initialStatuses: [],
  //       stockProducts: [],
  //       addOns: [],
  //       quantities: [],
  //       productionChannels: [],
  //       glAccounts: [],
  //     };
  //   }

  const [isOpen, setIsOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);

  const handleCustomFieldModal = (index: number | null) => {
    setIsOpen(!isOpen);
    setModalIndex(index);
  };

  const [quantities, setQuantities] = useState<{ text: string; value: string }[]>();
  const [attributes, setAttributes] = useState<T[]>();
  const [addOns, setAddOns] = useState<ModelTypes.ProductsAddOnsGlobal[]>();
  const [categories, setCategories] = useState<ModelTypes.ProductCategoryLegacy[]>();
  const [designTemplates, setDesignTemplates] = useState<ModelTypes.DesignTemplates[]>();
  const [stockProducts, setStockProducts] = useState<ModelTypes.ProductLegacy[]>();
  const [initialStatuses, setInitialStatuses] = useState<ModelTypes.JobStatuses[]>();
  const [productionChannels, setProductionChannels] = useState<ModelTypes.ProductionChannels[]>();
  const [preferredSuppliers, setPreferredSuppliers] = useState<ModelTypes.ProductionVendors[]>();

  const [workflowTemplates, setWorkflowTemplates] =
    useState<ModelTypes.JobSchedulerWorkflowTemplates[]>();
  const [impositionTemplates, setImpositionTemplates] =
    useState<ModelTypes.ImpositionTemplates[]>();

  const [glAccounts, setGlAccounts] = useState<ModelTypes.GeneralLedgerAccount[]>();

  useEffect(() => {
    const loadAll = async () => {
      try {
        await Feathers.service('/products-legacy/attributes')
          .find({
            query: {
              $np: 1,
              $sort: { name: 1 },
              $populate: ['entityAttribute'],
            },
          })
          .then((results: any) => {
            setAttributes(results);
            // setState({ ...state, attributes: results });
          });

        await Feathers.service('/products-legacy/add-ons-base')
          .find({
            query: {
              $sort: { name: 1 },
            },
          })
          .then((results: any) => {
            setAddOns(results);
            // setState({ ...state, addOns: results });
          });

        await Feathers.service('/products-legacy/categories')
          .find({
            query: {
              $np: 1,
              $sort: { name: 1 },
              $populate: ['entityAttributes'],
            },
          })
          .then((results: any) => {
            setCategories(results);
            // setState({ ...state, categories: results });
          });

        await Feathers.service('/design-templates')
          .find({
            query: {
              $np: 1,
              $sort: { name: 1 },
            },
          })
          .then((results: any) => {
            setDesignTemplates(results);
            // setState({ ...state, designTemplates: results });
          });

        await Feathers.service('job-scheduler/workflow-templates')
          .find({
            query: {
              $np: 1,
              $sort: { createdAt: 1 },
            },
          })
          .then((results: any) => {
            setWorkflowTemplates(results);
          });

        await Feathers.service('production/imposition-templates')
          .find({
            query: {
              $np: 1,
              $sort: { createdAt: 1 },
            },
          })
          .then((results: any) => {
            setImpositionTemplates(results);
          });

        await Feathers.service('/products-legacy')
          .find({
            query: {
              // $np: 1,
              // inventory: true,
              $sort: { createdAt: -1 },
            },
          })
          .then((results: any) => {
            setStockProducts(results);
            // setState({ ...state, stockProducts: results });
          });

        await Feathers.service('/order-item-statuses')
          .find({
            query: {
              $np: 1,
              $sort: { name: 1 },
            },
          })
          .then((results: any) => {
            setInitialStatuses(results);
            // setState({ ...state, initialStatuses: results });
          });

        await Feathers.service('/production/channels')
          .find({
            query: {
              $np: 1,
              $sort: { name: 1 },
            },
          })
          .then((results: any) => {
            setProductionChannels(results);
            // setState({ ...state, productionChannels: results });
          });

        await Feathers.service('/production/vendors')
          .find({
            query: {
              $np: 1,
              $sort: { companyName: 1 },
            },
          })
          .then((results: any) => {
            setPreferredSuppliers(results);
            // setState({ ...state, productionChannels: results });
          });

        await Feathers.service('/general-ledger-accounts')
          .find({
            query: {
              // $np: 1,
            },
          })
          .then((results: any) => {
            setGlAccounts(results?.data);
            // setState({ ...state, glAccounts: results?.data });
          });
      } catch (error) {
        console.error(error);
      }
    };

    loadAll();
  }, []);

  // UNSAFE_componentWillReceiveProps(next) {
  //   if (next.quantities && props.quantities !== next.quantities) {
  //     setState({
  //       ...state,
  //       quantities: next.quantities.map(q => ({
  //         text: String(q),
  //         value: String(q)
  //       }))
  //     });
  //   }
  // }

  // const onQuantityAdd = (e, value) => {
  //   const foundQty = quantities?.find(q => q.value === value.value);
  //   if (!foundQty) {
  //     const quantities = [...quantities];
  //     quantities.push({ text: value.value, value: value.value });
  //     setQuantities({ ...state, quantities });
  //     let v = props.quantities;
  //     if (!Array.isArray(v)) {
  //       v = [];
  //       props.changeFieldValue("quantities", v);
  //     }
  //   }
  // };

  function renderAttributes() {
    const foundCategory = categories?.find((c) => c._id === props.category);
    if (!foundCategory) {
      return null;
    }
    const chunkedArr = chunk(foundCategory.entityAttributes, 2);

    return chunkedArr.map((chunks, index) => {
      const ea = chunks.map((ea) => (
        <Form.Field>
          <label>{ea.name}</label>
          <Field
            key={ea._id}
            name={`attributes[${ea._id}]`}
            component={semanticFormDropdownField}
            as={Dropdown}
            options={attributes
              ?.filter((a) => a?.entityAttribute?._id === ea._id)
              .map((c) => ({ text: c.name, value: c._id }))}
            multiple={false}
            selection
            fluid
            search
            placeholder={ea.name}
            label={ea.name}
          />
        </Form.Field>
      ));
      return (
        <Form.Group key={`eaChunk${index}`} widths="equal">
          {ea}
        </Form.Group>
      );
    });
  }

  const renderCustomOptions = (innerProps) => {
    const {
      fields,
      meta: { error, submitFailed },
    } = innerProps;

    return (
      <div className="ui form">
        {fields?.map((field, index) => (
          <Form.Group widths="equal" key={index}>
            <Form.Field>
              <Field
                name={`${field}.key`}
                component={semanticFormField}
                as={Form.Input}
                validate={innerProps?.isOpen && [required]}
                label={'Key'}
              />
            </Form.Field>
            <Form.Field>
              <Field
                name={`${field}.value`}
                component={semanticFormField}
                as={Form.Input}
                validate={innerProps?.isOpen && [required]}
                label={'Value'}
              />
            </Form.Field>
          </Form.Group>
        ))}
        <Button
          size="tiny"
          content="Add Field"
          type="button"
          onClick={() => fields.push({ key: '', value: '' })}
        />
      </div>
    );
  };

  const renderCustomFields = (innerProps) => {
    const {
      fields,
      meta: { error, submitFailed },
    } = innerProps;

    return (
      <div>
        {fields?.map((field, index) => (
          <Form.Group widths="equal" key={index}>
            <Form.Field>
              <Field
                name={`${field}.label`}
                component={semanticFormField}
                as={Form.Input}
                // validate={[required]}
                label={'Label'}
              />
            </Form.Field>
            <Form.Field>
              <Field
                name={`${field}.key`}
                component={semanticFormField}
                as={Form.Input}
                // validate={[required]}
                label={'Key'}
              />
            </Form.Field>
            <Form.Field>
              <label>Type</label>
              <Field
                name={`${field}.type`}
                component={semanticFormDropdownField}
                as={Dropdown}
                options={customFieldOptions}
                selection
                fluid
                search
              />
              {(fields?.getAll()?.[index]?.type === 'select' ||
                fields?.getAll()?.[index]?.type === 'multi-select') && (
                <>
                  <div style={{ marginTop: '.5em' }}>
                    <Button
                      type="button"
                      size="tiny"
                      content={
                        fields?.getAll()?.[index]?.options?.length > 0
                          ? 'Edit Options'
                          : 'Add Options'
                      }
                      onClick={() => handleCustomFieldModal(index)}
                    />
                  </div>
                  {isOpen && modalIndex === index && (
                    <Modal
                      open={isOpen && modalIndex === index}
                      onClose={() => handleCustomFieldModal(null)}
                      closeIcon
                    >
                      <Modal.Header>Add Options</Modal.Header>
                      <Modal.Content>
                        <FieldArray
                          name={`${field}.options`}
                          component={renderCustomOptions}
                          isOpen={isOpen && modalIndex === index}
                        />
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          type="button"
                          size="tiny"
                          primary
                          content="Done"
                          onClick={() => handleCustomFieldModal(null)}
                        />
                        <Button
                          type="button"
                          size="tiny"
                          content="Close"
                          onClick={() => handleCustomFieldModal(null)}
                        />
                      </Modal.Actions>
                    </Modal>
                  )}
                </>
              )}
            </Form.Field>
            <Form.Field>
              <label>&nbsp;</label>
              <Field
                name={`${field}.isRequired`}
                component={Checkbox2}
                as={Form.Checkbox}
                label={'Required'}
                type="checkbox"
              />
            </Form.Field>
            <div>
              <div className="field">
                <label>&nbsp;</label>
                <Button type="button" icon="remove" onClick={() => fields.remove(index)} />
              </div>
            </div>
          </Form.Group>
        ))}

        <Button
          size="tiny"
          content="Add Field"
          type="button"
          onClick={() => fields.push({ type: 'text' })}
        />
      </div>
    );
  };

  const renderMetadata = (innerProps) => {
    const {
      fields,
      meta: { error, submitFailed },
    } = innerProps;

    return (
      <div>
        {fields?.map((field, index) => (
          <Form.Group widths="equal" key={index}>
            <Form.Field>
              <Field
                name={`${field}.key`}
                component={semanticFormField}
                as={Form.Input}
                // validate={[required]}
                label={'Key'}
              />
            </Form.Field>
            <Form.Field>
              <Field
                name={`${field}.value`}
                component={semanticFormField}
                as={Form.Input}
                label={'Value'}
              />
            </Form.Field>
            <div>
              <div className="field">
                <label>&nbsp;</label>
                <Button type="button" icon="remove" onClick={() => fields.remove(index)} />
              </div>
            </div>
          </Form.Group>
        ))}

        <Button
          size="tiny"
          content="Add Field"
          type="button"
          onClick={() => fields.push({ type: 'text' })}
        />
      </div>
    );
  };

  const renderCleaveField = ({ input, meta: { error, warning, touched } }) => (
    <Form.Field error={error && error !== undefined}>
      <div className="ui input">
        <CleaveReact
          style={{ textAlign: 'right' }}
          {...input}
          // onChange={e => field.input.onChange(e.target.rawValue)}
          options={{
            numeral: true,
            rawValueTrimPrefix: true,
            numeralPositiveOnly: true,
            numeralThousandsGroupStyle: 'thousand',
            prefix: '$',
          }}
        />
      </div>
      {touched &&
        ((error && (
          <span>
            <i>{error}</i>
          </span>
        )) ||
          (warning && (
            <span>
              <i>{warning}</i>
            </span>
          )))}
    </Form.Field>
  );

  const renderPrices = ({ changeFieldValue, fields, meta: { error, submitFailed } }) => (
    <>
      {fields?.length > 5 && (
        <Table.Row>
          <Table.Cell>
            <Button
              size="tiny"
              content="Add Quantity"
              type="button"
              onClick={() => fields.unshift({ price: 0.0 })}
            />
          </Table.Cell>
        </Table.Row>
      )}
      {fields?.map((q, index) => (
        <Table.Row key={index}>
          <Table.Cell width="3">
            <Field name={`${q}.unitValue`} component={semanticFormField} as={Form.Input} />
          </Table.Cell>
          <Table.Cell width="3">
            <Field name={`${q}.cost`} component={renderCleaveField} normalize={currencyFormat} />
          </Table.Cell>
          <Table.Cell width="3">
            <Field
              name={`${q}.price`}
              component={renderCleaveField}
              normalize={currencyFormat}
              validate={[requiredNumber]}
            />
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Button size="tiny" icon="trash" type="button" onClick={() => fields.remove(index)} />
          </Table.Cell>
        </Table.Row>
      ))}
      <Table.Row>
        <Table.Cell>
          <Button
            size="tiny"
            content="Add Quantity"
            type="button"
            onClick={() => fields.push({ price: 0.0 })}
          />
        </Table.Cell>
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
      </Table.Row>
    </>
  );

  function renderSizeParams() {
    const foundCategory = categories?.find((c) => c._id === props.category);
    if (!foundCategory) {
      return null;
    }

    if (foundCategory?.pricingMethod === '1' || foundCategory.pricingMethod === '2') {
      return null;
    }

    return (
      <div>
        <Header attached="top" as="h5">
          Shipping Settings
        </Header>
        <Segment attached>
          <Form.Group widths={'equal'}>
            <Field
              name="minimumWidth"
              component={semanticFormField}
              as={Form.Input}
              label={'Minimum Width'}
            />
            <Field
              name="maximumWidth"
              component={semanticFormField}
              as={Form.Input}
              label={'Maximum Width'}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <Field
              name="minimumHeight"
              component={semanticFormField}
              as={Form.Input}
              label={'Minimum Height'}
            />
            <Field
              name="maximumHeight"
              component={semanticFormField}
              as={Form.Input}
              label={'Maximum Height'}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <Field
              name="minimumLength"
              component={semanticFormField}
              as={Form.Input}
              label={'Minimum Length'}
            />
            <Field
              name="maximumLength"
              component={semanticFormField}
              as={Form.Input}
              label={'Maximum Length'}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <Field
              name="minimumDepth"
              component={semanticFormField}
              as={Form.Input}
              label={'Minimum Depth'}
            />
            <Field
              name="maximumDepth"
              component={semanticFormField}
              as={Form.Input}
              label={'Maximum Depth'}
            />
          </Form.Group>
        </Segment>
      </div>
    );
  }

  const renderPriceBreaks = ({ changeFieldValue, fields, meta: { error, submitFailed } }) => {
    const newFields = fields.getAll();

    // Following line caused Rollbar #1539. sortedPriceBreaks was not being used.
    // const sortedPriceBreaks = newFields.sort((a, b) => a.breakAmount - b.breakAmount)

    return (
      <div>
        {fields.map((t, index) => {
          const myRow = fields.get(index);

          let style;
          let isCostHigher = false;
          if (
            myRow.cost !== '' &&
            myRow.price !== '' &&
            Number(myRow.cost) >= Number(myRow.price)
          ) {
            isCostHigher = true;
            style = {
              backgroundColor: '#ffff00',
              // color: 'white',
              paddingBottom: 8,
              marginBottom: 0,
            };
          }

          return (
            <>
              <Form.Group widths={'equal'} key={index} style={style}>
                <Field
                  name={`${t}.breakAmount`}
                  component={semanticFormField}
                  as={Form.Input}
                  label={'Break Quantity'}
                />
                <Field
                  name={`${t}.price`}
                  component={semanticFormField}
                  as={Form.Input}
                  label={'Sell Price'}
                  style={style}
                />
                <Field
                  name={`${t}.cost`}
                  component={semanticFormField}
                  as={Form.Input}
                  label={'Cost'}
                  style={style}
                />
                <Field
                  name={`${t}.pricePerQuantity`}
                  component={semanticFormField}
                  as={Form.Input}
                  label={'Price Per Quantity'}
                />
                <Field
                  name={`${t}.costPerQuantity`}
                  component={semanticFormField}
                  as={Form.Input}
                  label={'Cost Per Quantity'}
                />
                <div>
                  <div className="field">
                    <label>&nbsp;</label>
                    <Button type="button" icon="remove" onClick={() => fields.remove(index)} />
                  </div>
                </div>
              </Form.Group>
              {isCostHigher && (
                <div key={`${index}-desc`} className="fields" style={{ ...style, padding: 4 }}>
                  Warning: Cost is equal or greater than sell price
                </div>
              )}
              {}
            </>
          );
        })}

        <Button
          size="tiny"
          content="Add Price Break"
          type="button"
          onClick={() => fields.push({})}
        />
      </div>
    );
  };

  function renderGeneralLedger() {
    const glGroupBy = groupBy(glAccounts);

    return (
      <div>
        <Header attached="top" as="h5">
          General Ledger
        </Header>
        <Segment attached>
          <Form.Group widths={'equal'}>
            <Form.Field>
              <label>GL Income Account</label>
              <Field
                name="glIncomeAccount"
                component={semanticFormDropdownField}
                as={Dropdown}
                options={glGroupBy?.income?.map((g) => ({
                  text: g.accountNumber,
                  value: g._id,
                }))}
                multiple={false}
                selection
                search
                fluid
                clearable
                placeholder="GL Income Account"
              />
            </Form.Field>
            <Form.Field>
              <label>GL Expense Account</label>

              <Field
                name="glExpenseAccount"
                component={semanticFormDropdownField}
                as={Dropdown}
                options={glGroupBy?.['cost-of-goods']?.map((g) => ({
                  text: g.accountNumber,
                  value: g._id,
                }))}
                multiple={false}
                selection
                search
                fluid
                clearable
                placeholder="GL Expense Account"
              />
            </Form.Field>
          </Form.Group>
        </Segment>
      </div>
    );
  }

  const renderLinkedStockProducts = ({
    innerStockProducts,
    fields,
    meta: { error, submitFailed },
  }) => (
    <div>
      {fields.map((t, index) => (
        <Form.Group widths={'equal'} key={index}>
          <div className="field">
            <label>Product</label>

            <Field
              name={`${t}.product`}
              component={renderAsyncSelectField}
              loadOptions={handleLoadProducts}
              cacheOptions
              placeholder="Search products..."
              isClearable
              noOptionsMessage={(v) =>
                !v.inputValue ? 'Start typing a a product name to search...' : 'No options'
              }
            />
          </div>
          <Field
            name={`${t}.quantity`}
            component={semanticFormField}
            as={Form.Input}
            label={'Quantity'}
          />
          <div>
            <div className="field">
              <label>&nbsp;</label>
              <Button type="button" icon="remove" onClick={() => fields.remove(index)} />
            </div>
          </div>
          <div>
            <div className="field">
              <label>&nbsp;</label>
              <Button
                icon="plus"
                type="button"
                onClick={() => fields.push({ product: '', quantity: 1 })}
              />
            </div>
          </div>
        </Form.Group>
      ))}

      {fields?.length < 1 && (
        <>
          <Message info content="There are not any linked stock products" />

          <Button
            size="tiny"
            content="Add"
            type="button"
            onClick={() => fields.push({ product: '', quantity: 1 })}
          />
        </>
      )}
    </div>
  );

  const renderAssociatedProducts = ({
    innerStockProducts,
    fields,
    meta: { error, submitFailed },
  }) => (
    <div>
      {fields.map((t, index) => (
        <Form.Group widths={'equal'} key={index}>
          <div className="field">
            <label>Product</label>

            <Field
              name={`${t}.product`}
              component={renderAsyncSelectField}
              loadOptions={handleLoadProducts}
              cacheOptions
              placeholder="Search products..."
              isClearable
              noOptionsMessage={(v) =>
                !v.inputValue ? 'Start typing a a product name to search...' : 'No options'
              }
            />
          </div>
          {/* <Field
            name={`${t}.quantity`}
            component={semanticFormField}
            as={Form.Input}
            label={'Quantity'}
          /> */}
          <div>
            <div className="field">
              <label>&nbsp;</label>
              <Button type="button" icon="remove" onClick={() => fields.remove(index)} />
            </div>
          </div>
          <div>
            <div className="field">
              <label>&nbsp;</label>
              <Button
                icon="plus"
                type="button"
                onClick={() => fields.push({ product: '', quantity: 1 })}
              />
            </div>
          </div>
        </Form.Group>
      ))}

      {fields?.length < 1 && (
        <>
          <Message info content="There are not any associated products" />

          <Button
            size="tiny"
            content="Add"
            type="button"
            onClick={() => fields.push({ product: '', quantity: 1 })}
          />
        </>
      )}
    </div>
  );

  const renderImages = ({ changeFieldValue, fields }) => (
    <div>
      {
        <Segment>
          <Image.Group size="small">
            {fields?.getAll()?.map((image: any, index: number) => (
              <Card
                key={index}
                index={index}
                id={image._id}
                contentImage={image.contentImage}
                src={`${baseUrl}/${image.url}`}
                remove={() => fields.remove(index)}
                move={(a, b) => fields.move(a, b)}
              />
            ))}
          </Image.Group>
          <Button
            type="button"
            icon="download"
            content="Download All"
            onClick={() => DownloadZip(fields?.getAll()?.map((img) => `${baseUrl}/${img.url}`))}
          />
        </Segment>
      }

      <ImageUpload
        onUpload={(files) => {
          const newFiles = files.map((f) => ({
            url: f.publicUrl,
            contentImage: f,
          }));

          fields.push(newFiles[0]);
        }}
      />
    </div>
  );

  const renderArtworkVariants = ({ changeFieldValue, fields, meta: { error, submitFailed } }) => {
    const allFields =
      fields.getAll() && fields.getAll().length
        ? fields
            .getAll()
            .sort((a, b) =>
              Number(a.priority) < Number(b.priority)
                ? -1
                : Number(a.priority) > Number(b.priority)
                ? 1
                : 0
            )
        : [];

    return (
      <div>
        {allFields.map((member, index) => {
          const name = `${fields.name}[${index}]`;
          const artworkValue = fields.get(index).artwork;
          return (
            <Form.Group widths={'equal'} key={index}>
              <Field
                name={`${name}.type`}
                component={semanticFormField}
                as={Form.Input}
                label={'Type'}
              />
              <Field
                name={`${name}.value`}
                component={semanticFormField}
                as={Form.Input}
                label={'Value'}
              />
              <Field
                name={`${name}.priority`}
                component={semanticFormField}
                as={Form.Input}
                label={'Priority'}
                onChange={(e, data) => changeFieldValue(`${name}.priority`, data.value)}
              />
              {artworkValue && (
                <Form.Field>
                  <label>Current Artwork</label>
                  {artworkValue}
                </Form.Field>
              )}
              <Form.Field>
                <label>&nbsp;</label>
                <ArtworkVariantUpload
                  multiple={false}
                  onUpload={(data) => {
                    changeFieldValue(`${name}.artwork`, data.files[0].cloudFilename);
                  }}
                />
              </Form.Field>

              <div>
                <div className="field">
                  <label>&nbsp;</label>
                  <Button type="button" icon="remove" onClick={() => fields.remove(index)} />
                </div>
              </div>
            </Form.Group>
          );
        })}

        <Button
          size="tiny"
          content="Add Artwork Variant"
          type="button"
          onClick={() => fields.push({})}
        />
      </div>
    );
  };

  // render() {
  const artworkSequenceOptions = [
    { label: 'Front', value: 'FRONT' },
    { label: 'Back', value: 'BACK' },
    { label: 'Spot UV Front', value: 'SPOTUVFRONT' },
    { label: 'Spot UV Back', value: 'SPOTUVBACK' },
    { label: 'Multi-page PDF', value: 'MULTIPAGEPDF' },
    { label: 'Die Line', value: 'DIELINE' },
  ];

  const artworkOptions = [
    { label: 'Requires artwork', value: 'true' },
    { label: 'Artwork not required', value: 'false' },
  ];

  let showQuantities = false;
  const foundCategory = categories?.find((c) => c._id === props.category);
  if (foundCategory) {
    // isSizesFixed: values.pricingMethod === "1" || values.pricingMethod === "2" || false,
    // isQuantitiesFixed: values.pricingMethod === "1" || false,
    showQuantities = foundCategory?.pricingMethod === '1';
  }

  const { errorMessage, hasVariants } = props;

  const handleAddition = (e, data: any) => {
    const foundTag = props?.tags?.find((tag) => tag === data.value);

    if (!foundTag) {
      props.changeFieldValue('tags', [...(props?.tags || []), data.value]);
    }
  };

  const hasNumPages = Object.keys(props?.initialValues?.attributes ?? {}).find(
    (a) => a === 'NUM_PAGES'
  );

  const handleSlugify = (v) => v && slugifyKey(v);
  const handleNameChange = (e, data) =>
    !props?.initialValues?._id && props.changeFieldValue('key', handleSlugify(data));

  const handleDownloadArtwork = async () => {
    try {
      const res: any = await Feathers.service('products-legacy/artwork-download').create(
        {
          product: props?.initialValues?._id,
        },
        { query: {} }
      );

      window.open(res.url, '_blank');
    } catch (e) {
      console.log('e', e);
    }
  };

  const startAt = typeof props.startAt === 'string' ? parseISO(props.startAt) : props.startAt;
  const expireAt = typeof props.expireAt === 'string' ? parseISO(props.expireAt) : props.expireAt;

  // console.log({ props });

  // const requiredDesignTemplate = props.canOrderDigital && props.designTemplates?.length < 1;

  return (
    <Form onSubmit={props.handleSubmit} error={!!errorMessage}>
      <Header attached="top" as="h5">
        Basic Information
      </Header>
      <Segment attached="bottom">
        <Form.Group widths={'equal'}>
          <Field
            name="staticName"
            component={semanticFormField}
            as={Form.Input}
            label={'Static name'}
            onChange={handleNameChange}
          />
          <Field
            name="key"
            component={semanticFormField}
            as={Form.Input}
            label={'Key/SKU'}
            normalize={handleSlugify}
          />
          <Field
            name="vanitySku"
            component={semanticFormField}
            as={Form.Input}
            label={'Vanity SKU'}
          />
          <Field
            name="aliasSku"
            component={semanticFormField}
            as={Form.Input}
            label={'Alias SKU'}
          />
        </Form.Group>
        <Field
          name="shortDescription"
          component={semanticFormField}
          as={Form.TextArea}
          label={'Short Description'}
          type="textarea"
        />
        <Field
          name="vendorDescription"
          component={semanticFormField}
          as={Form.TextArea}
          label={'Vendor Description'}
          type="textarea"
        />
      </Segment>

      <Header attached="top" as="h5">
        Select a Category
      </Header>
      <Segment attached>
        <Field
          name="category"
          component={semanticFormDropdownField}
          as={Dropdown}
          options={categories?.map((c) => ({
            text: c.internalName || c.name,
            value: c._id,
          }))}
          multiple={false}
          selection
          search
          fluid
          // /label='Category'
          placeholder="Category"
        />
      </Segment>

      {props.category && (
        <Segment basic padded={false} style={{ padding: 0 }}>
          <Header attached="top" as="h5">
            Select all attributes
          </Header>
          <Segment attached="bottom">
            <Message>
              Attributes are controlled at the category level. This product is in{' '}
              {(foundCategory?.internalName ?? '') || (foundCategory?.name ?? '')} category. Click{' '}
              <Link to={`/products/categories/${foundCategory?._id}`}>here</Link> to edit the
              category settings
            </Message>
            {renderAttributes()}

            <hr />
            <br />

            <Field
              name="printingNotes"
              component={semanticFormField}
              as={Form.TextArea}
              lines={2}
              label={'Printing Notes'}
            />
            <Field
              name="packingNotse"
              component={semanticFormField}
              as={Form.TextArea}
              label={'Packing Notes'}
            />
            <Field
              name="kittingNotes"
              component={semanticFormField}
              as={Form.TextArea}
              label={'Kitting Notes'}
            />

            <Field
              name="attributeSummary"
              component={semanticFormField}
              as={Form.Input}
              label={'Attribute Summary'}
            />

            <Field
              name="attributeSummaryId"
              component={semanticFormField}
              as={Form.Input}
              label={'Attribute Summary ID'}
            />
          </Segment>
        </Segment>
      )}

      {renderSizeParams()}

      {renderGeneralLedger()}

      {/* {showQuantities && (
          <>
            <Header attached="top" as="h5">
              Quantites
            </Header>
            <Segment attached="bottom">
              <Field name="quantities" component={DropdownTags} />
              <Message size="small">Enter each quantity and hit enter.</Message>
            </Segment>
          </>
        )} */}

      {/* {!showQuantities && (
        <>
          <Header attached="top" as="h5">
            Quantity Settings
          </Header>
          <Segment attached="bottom">
            <label>Minimum Quantity</label>
            <Field name="quantitySettings.min" component={semanticFormField} as={Form.Input} />
          </Segment>
        </>
      )} */}

      {/* {props?.initialValues?.prices?.length > 0 && (
          <> */}
      {showQuantities ? (
        <>
          <Header attached="top" as="h5">
            Quantities &amp; Pricing
          </Header>
          <Segment attached="bottom">
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="3">Quantity</Table.HeaderCell>
                  <Table.HeaderCell width="3">Cost</Table.HeaderCell>
                  <Table.HeaderCell width="3">Price</Table.HeaderCell>
                  <Table.HeaderCell width="1" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <FieldArray
                  name="prices"
                  changeFieldValue={props.changeFieldValue}
                  component={renderPrices}
                />
              </Table.Body>
            </Table>
          </Segment>{' '}
        </>
      ) : (
        <>
          <Header attached="top" as="h5">
            Quantity Settings
          </Header>
          <Segment attached="bottom">
            <Form.Group widths="equal">
              <Form.Field>
                <label>Minimum Quantity</label>
                <Field name="quantitySettings.min" component={semanticFormField} as={Form.Input} />
              </Form.Field>
              <Form.Field>
                <label>Maximum Quantity</label>
                <Field name="quantitySettings.max" component={semanticFormField} as={Form.Input} />
              </Form.Field>
              <Form.Field>
                <label>Step</label>
                <Field name="quantitySettings.step" component={semanticFormField} as={Form.Input} />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Field
                name="quantitySettings.includeOne"
                label="Include one"
                component={Checkbox2}
                as={Form.Checkbox}
              />
            </Form.Field>
          </Segment>
        </>
      )}

      {props?.initialValues?.publishedToMicrosites?.length && (
        <>
          <Header attached="top" as="h5">
            Published Pages
          </Header>
          <Segment attached="bottom">
            <PagePublish
              productId={props?.initialValues?._id}
              publishedToMicrosites={props?.initialValues?.publishedToMicrosites}
            />
          </Segment>
        </>
      )}

      {foundCategory?.pricingMethod !== '1' && (
        <>
          <Header attached="top" as="h5">
            Price &amp; Cost
          </Header>
          <Segment attached="bottom">
            <Form.Group widths="equal">
              <Form.Field>
                <label>Price</label>
                <Field name="price" component={semanticFormField} as={Form.Input} />
              </Form.Field>
              <Form.Field>
                <label>Cost</label>
                <Field name="cost" component={semanticFormField} as={Form.Input} />
              </Form.Field>
            </Form.Group>
          </Segment>
        </>
      )}

      <Header attached="top" as="h5">
        Setup Price
      </Header>
      <Segment attached="bottom">
        <Field name="setupPrice" component={semanticFormField} as={Form.Input} />
      </Segment>

      <Header attached="top" as="h5">
        Price Breaks
      </Header>
      <Segment attached="bottom">
        <FieldArray
          name="priceBreaks"
          changeFieldValue={props.changeFieldValue}
          component={renderPriceBreaks}
        />
      </Segment>

      <Header attached="top" as="h5">
        Linked Stock Products
      </Header>
      <Segment attached="bottom">
        <Message size="small">
          Link to stock products that are deducted from inventory when this product is ordered. Note
          that the item does not appear on the actual order or as a job card but is solely meant as
          a consumable for the main product.
        </Message>

        <FieldArray
          name="linkedStockProducts"
          stockProducts={stockProducts}
          component={renderLinkedStockProducts}
        />
      </Segment>

      <Header attached="top" as="h5">
        Associated Products
      </Header>
      <Segment attached="bottom">
        <Message size="small">
          Link to related products that you want clients or your sales reps to be able to add to an
          order when this product is ordered.
        </Message>

        <FieldArray
          name="associatedProducts"
          stockProducts={stockProducts}
          component={renderAssociatedProducts}
        />
      </Segment>

      <Header attached="top" as="h5">
        Images
      </Header>
      <Segment attached="bottom">
        <DndProvider backend={HTML5Backend}>
          <FieldArray
            name="images"
            changeFieldValue={props.changeFieldValue}
            component={renderImages}
          />
        </DndProvider>
      </Segment>

      <Header attached="top" as="h5">
        Automation
      </Header>
      <Segment attached="bottom">
        <Form.Group widths="equal">
          <Form.Field>
            <label>Show</label>
            <DatePicker
              dateFormat="MM/dd/yyyy h:mm aa"
              selected={startAt}
              selectsStart
              showTimeSelect
              startDate={startAt}
              endDate={expireAt}
              onChange={(date: Date) => props.changeFieldValue('startAt', date)}
            />
          </Form.Field>
          <Form.Field>
            <label>Hide</label>
            <DatePicker
              dateFormat="MM/dd/yyyy h:mm aa"
              selected={expireAt}
              selectsEnd
              showTimeSelect
              startDate={startAt}
              endDate={expireAt}
              onChange={(date: Date) => props.changeFieldValue('expireAt', date)}
            />
          </Form.Field>
        </Form.Group>
      </Segment>

      <Field
        name="isComposite"
        component={Checkbox2}
        as={Form.Checkbox}
        label={'This is a composite product'}
        type="checkbox"
      />

      {props.isComposite && (
        <>
          <Field
            name="canUserEnterQuantitesComposite"
            component={Checkbox2}
            as={Form.Checkbox}
            label="Can User Enter Quantities"
            type="checkbox"
          />
          <Field
            name="syncCompositeProduct"
            component={Checkbox2}
            as={Form.Checkbox}
            label={'Sync Composite Product'}
            type="checkbox"
          />

          <Header as="h5" attached="top">
            Composite Included Products
          </Header>
          <Segment attached="bottom">
            <FieldArray name="compositeProducts" component={Composite} />
            {/* <FastField
                      name="compositeProducts"
                      render={({ field }: FieldProps<any>) => {
                        return (
                          <Composite
                            value={field.value}
                            onChange={value => {
                              field.onChange({
                                target: { value, name: field.name }
                              });
                            }}
                          />
                        );
                      }}
                    /> */}
          </Segment>
        </>
      )}

      <Header attached="top" as="h5">
        Custom Fields
      </Header>
      <Segment attached="bottom">
        <FieldArray name="customFieldsSchema" component={renderCustomFields} />
      </Segment>

      <Header attached="top" as="h5">
        Metadata
      </Header>
      <Segment attached="bottom">
        <FieldArray name="metadata" component={renderMetadata} />
      </Segment>

      <Header attached="top" as="h5">
        RFQ #
      </Header>
      <Segment attached="bottom">
        <Field name="rfqNum" component={semanticFormField} as={Form.Input} />
      </Segment>

      <Header attached="top" as="h5">
        Additional Per Piece Price
      </Header>
      <Segment attached="bottom">
        <Field
          name="isAdditionalPerPieceEnabled"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Enable Additional Per Piece'}
          type="checkbox"
        />
        {props.isAdditionalPerPieceEnabled && (
          <Field name="additionalPerPiecePrice" component={semanticFormField} as={Form.Input} />
        )}
      </Segment>

      {hasVariants && (
        <>
          <Header as="h5" content="Child Variants" attached="top" />
          <VariantTable id={props?.initialValues?._id} type="on-demand" attached="bottom" />
        </>
      )}

      <Header attached="top" as="h5">
        Add Tags
      </Header>
      <Segment attached>
        <Field
          name="tags"
          component={semanticFormDropdownField}
          as={Dropdown}
          options={
            props?.tags?.map((tag: string) => ({
              text: tag,
              value: tag,
              key: tag,
            })) || []
          }
          multiple={true}
          selection
          fluid
          search
          allowAdditions
          onAddItem={handleAddition}
          placeholder="Tags..."
        />
      </Segment>

      <Header attached="top" as="h5">
        Select Add-ons
      </Header>
      <Segment attached>
        <Field
          name="addOns"
          component={semanticFormDropdownField}
          as={Dropdown}
          options={addOns?.map((c) => ({
            text: c.name,
            value: c._id,
          }))}
          multiple={true}
          selection
          fluid
          search
          // /label='Category'
          placeholder="Add Ons..."
        />
      </Segment>

      <Header attached="top" as="h5">
        Production Channel
      </Header>
      <Segment attached>
        <Field
          name="productionChannel"
          component={semanticFormDropdownField}
          as={Dropdown}
          options={productionChannels?.map((c) => ({
            text: `${c?.name ?? ''} - ${c.key}`,
            value: c._id,
          }))}
          multiple={false}
          selection
          clearable
          search
          fluid
          placeholder="Production Channel"
        />
      </Segment>

      <Header attached="top" as="h5">
        Preferred Supplier
      </Header>
      <Segment attached>
        <Field
          name="preferredSupplier"
          component={semanticFormDropdownField}
          as={Dropdown}
          options={preferredSuppliers?.map((c) => ({
            text: c.companyName ?? 'N/A',
            value: c._id,
          }))}
          multiple={false}
          selection
          clearable
          search
          fluid
          placeholder="Preferred Supplier"
        />

        <Field
          name="supplierPo"
          component={semanticFormField}
          as={Form.Input}
          label={'Purchase Order (optional)'}
        />
        <Message size="small">
          Used with billing reconciliation. When a billing reconciliation request is sent to a
          supplier, the supplier will see all linked purchase orders numbers.
        </Message>
      </Segment>

      <Header attached="top" as="h5">
        Client Billing
      </Header>
      <Segment attached>
        <Field
          name="customerPo"
          component={semanticFormField}
          as={Form.Input}
          label={"Customer's Purchase Order (optional)"}
        />
        <Message size="small">Can be utilized in billing reconciliation</Message>

        <Field
          name="costCenter"
          component={semanticFormField}
          as={Form.Input}
          label={'Cost Center (optional)'}
        />
        <Message size="small">Can be utilized in billing reconciliation</Message>
      </Segment>

      <Header attached="top" as="h5">
        Turnaround Time
      </Header>
      <Segment attached>
        <Message size="small">
          Enter turnaround time in days. Minimum and maximum can be equal or two different values
        </Message>

        <Form.Group widths={'equal'}>
          <Field
            name="timeMin"
            component={semanticFormField}
            as={Form.Input}
            label={'Minimum Turnaround Time'}
          />
          <Field
            name="timeMax"
            component={semanticFormField}
            as={Form.Input}
            label={'Maximum Turnaround Time'}
          />
        </Form.Group>
      </Segment>

      <Header attached="top" as="h5">
        Artwork Variants
      </Header>
      <Segment attached="bottom">
        <FieldArray
          name="artworkVariantValues"
          changeFieldValue={props.changeFieldValue}
          component={renderArtworkVariants}
        />
      </Segment>

      <Field
        name="canOrderDigital"
        component={Checkbox2}
        as={Form.Checkbox}
        label="Can Order Digital"
        type="checkbox"
      />
      {props.canOrderDigital && (
        <div className="ui field">
          <label>Digital Price</label>
          <Field
            name="priceDigital"
            as={Form.Input}
            component={renderCleaveField}
            normalize={currencyFormat}
            label="Price Digital"
          />
        </div>
      )}
      <Header attached="top" as="h5">
        Connect Design Templates
      </Header>
      <Segment attached="bottom">
        <Field
          name="designTemplates"
          // validate={requiredDesignTemplate && required}
          component={semanticFormDropdownField}
          as={Dropdown}
          options={designTemplates?.map((c) => ({
            text: c.name,
            value: c._id,
          }))}
          multiple={true}
          selection
          clearable
          search
          fluid
          onChange={(e, data) => {
            props.changeFieldValue('designTemplates', data);
            props.changeFieldValue('isArtworkRequired', true);
            props.changeFieldValue('artworkSlots', ['MULTIPAGEPDF']);
          }}
          // className={requiredDesignTemplate && 'error'}
          placeholder="Design Templates"
        />
      </Segment>
      <Header attached="top" as="h5">
        Job Scheduling Workflow Template
      </Header>
      <Segment attached="bottom">
        <Field
          name="workflowTemplate"
          component={semanticFormDropdownField}
          as={Dropdown}
          options={workflowTemplates?.map((c) => ({
            text: c.name,
            value: c._id,
          }))}
          multiple={true}
          selection
          clearable
          search
          fluid
          onChange={(e, data) => {
            props.changeFieldValue('workflowTemplate', data);
          }}
          placeholder=""
        />
      </Segment>
      <Header attached="top" as="h5">
        Impostion Template{' '}
      </Header>
      <Segment attached="bottom">
        <Field
          name="impositionTemplate"
          component={semanticFormDropdownField}
          as={Dropdown}
          options={impositionTemplates?.map((c) => ({
            text: `${c?.sheetWidth ?? ''} x ${c?.sheetHeight ?? ''} / ${c?.width ?? ''} x ${
              c?.height ?? ''
            }`,
            value: c._id,
          }))}
          selection
          clearable
          search
          fluid
          onChange={(e, data) => {
            props.changeFieldValue('impositionTemplate', data);
          }}
          placeholder=""
        />
      </Segment>

      <Header attached="top" as="h5">
        Artwork Settings
      </Header>
      <Segment attached="bottom">
        <Field
          name="isArtworkRequired"
          component={semanticFormRadioGroupField}
          label={'Require artwork upload'}
          options={artworkOptions}
          normalize={normalizeBoolean}
          format={formatBoolean}
          disabled={props.artwork || props.designTemplates?.length > 0}
        />
        {props.isArtworkRequired && (
          <React.Fragment>
            <Field
              name="artworkSlots"
              component={semanticFormCheckboxGroupField}
              options={artworkSequenceOptions}
              label={'What files are required?'}
              disabled={props.artwork || props.designTemplates?.length > 0}
            />
            <Form.Field>
              <FixedArtworkUpload
                onUpload={(v) => {
                  if (v.files && v.files[0]) {
                    const f = v.files[0];
                    props.changeFieldValue('artwork', f.cloudFilename);
                    props.changeFieldValue('artworkSlots', ['MULTIPAGEPDF']);
                  }
                }}
              />
              {props.artwork && (
                <div>
                  <Icon name="file" />
                  {props.artwork}{' '}
                  <Button
                    size="mini"
                    icon="remove"
                    basic
                    onClick={() => props.changeFieldValue('artwork', null)}
                  />
                  <Button
                    type="button"
                    size="mini"
                    icon="download"
                    basic
                    onClick={() => handleDownloadArtwork()}
                  />
                </div>
              )}
            </Form.Field>
          </React.Fragment>
        )}
      </Segment>

      <Header attached="top" as="h5">
        Shipping Settings
      </Header>
      <Segment attached>
        <Form.Group>
          <Field
            name="isShippable"
            component={semanticFormRadioGroupField}
            label={''}
            options={[
              { label: 'Product is shippable', value: 'true' },
              { label: 'Not a shippable product', value: 'false' },
            ]}
            normalize={normalizeBoolean}
            format={formatBoolean}
          />
        </Form.Group>

        {props.isShippable && (
          <>
            <Form.Group widths={'equal'}>
              <Field
                name="weightUnit"
                component={semanticFormField}
                as={Form.Input}
                validate={[required, number]}
                label={'Weight'}
              />
              <Field
                name="weightBasis"
                component={semanticFormField}
                as={Form.Input}
                validate={[required, number]}
                label={'Quantity for weight'}
              />
            </Form.Group>

            <Field
              name="packagingInstructions"
              component={semanticFormField}
              as={Form.Input}
              label={'Packaging Instructions'}
            />
          </>
        )}
      </Segment>

      <Header attached="top" as="h5">
        Other Settings
      </Header>
      <Segment attached="bottom">
        {/* <Field
            name="isNameStatic"
            component={semanticFormRadioGroupField}
            label={""}
            options={[
              { label: "Generate from attributes", value: "false" },
              { label: "Enter a static name", value: "true" }
            ]}
            normalize={normalizeBoolean}
            format={formatBoolean}
          />

          {props.isNameStatic && (

          )} */}

        <Field
          name="description"
          component={semanticFormField}
          as={Form.TextArea}
          label={'Optional Description'}
          type="textarea"
        />

        <Form.Group widths={'equal'}>
          <Field
            name="unitOfMeasure"
            component={semanticFormField}
            as={Form.Input}
            label={'Unit of Measure'}
          />
          <Field
            name="unitOfMeasureConversion"
            component={semanticFormField}
            as={Form.Input}
            label={'Unit of Measure Conversion'}
          />
        </Form.Group>

        <div className="field">
          <label>Initial Status</label>
          <Field
            name="initialStatus"
            component={semanticFormDropdownField}
            as={Dropdown}
            options={initialStatuses?.map((c) => ({
              text: c.name,
              value: c._id,
            }))}
            multiple={false}
            selection
            clearable
            search
            fluid
          />
        </div>

        <Field name="glCode" component={semanticFormField} as={Form.Input} label={'GL Code'} />

        <Field
          name="isCustomTrimEnabled"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Enable custom trim'}
          type="checkbox"
        />

        <Field
          name="isTaxable"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Is Taxable'}
          type="checkbox"
        />

        <Field
          name="isRushEnabled"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Enable rush service'}
          type="checkbox"
        />

        <Field
          name="triggerOrderApproval"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Triggers a required approval'}
          type="checkbox"
        />

        <Field
          name="isHidden"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Hide Product'}
          type="checkbox"
        />

        <Field
          name="isDesignEnabled"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Enable design service'}
          type="checkbox"
        />

        <Field
          name="calculateBasedOnTotalQty"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Calculate based on total quantity'}
          type="checkbox"
        />

        <Field
          name="isDigitalCanImmediateCheckout"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Enable digital immediate checkout'}
          type="checkbox"
        />

        <Field
          name="calculateQuantityFromData"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Calculate quantity from data'}
          type="checkbox"
        />

        <Field
          name="shouldExcludeFromHandlingFees"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Exclude from Handling Fee Calculation'}
          type="checkbox"
        />

        <Field
          name="isOutOfStockManual"
          component={Checkbox2}
          as={Form.Checkbox}
          label={'Set out of stock manually'}
          type="checkbox"
        />

        {hasNumPages && (
          <Field
            name="pricePerPageEnabled"
            component={Checkbox2}
            as={Form.Checkbox}
            label={'Price per page'}
            type="checkbox"
          />
        )}

        {props.isDesignEnabled && (
          <Field
            name="designPrice"
            component={renderCleaveField}
            normalize={currencyFormat}
            label={'Design price'}
          />
        )}

        <Field
          name="artworkTransporterDestination"
          component={Form.Input}
          // normalize={currencyFormat}
          label={'Artwork Transporer Destination'}
        />

        <Field
          name="priority"
          component={semanticFormField}
          as={Form.Input}
          label={'Sort Priority'}
        />
        <Segment size="mini" compact secondary>
          Used to control the sort appearance on the store front. Sorts from smallest to biggest
          values
        </Segment>
      </Segment>
      {errorMessage && <Message error content={errorMessage} />}
      <Button primary disabled={props.pristine || props.submitting}>
        Submit
      </Button>
      <Link to={'/products/products'}>
        <Button type="button">Back</Button>
      </Link>
    </Form>
  );
};

const formName = 'product';
const reduxedForm: any = reduxForm({
  form: formName,
  validate,
  asyncValidate,
  enableReinitialize: true,
  asyncBlurFields: ['key'],
})(ProductForm as any);

const selector = formValueSelector(formName);

const mapStateToProps = (state, props) => ({
  initialValues: {
    ...props.initialValues,
    artworkSlots:
      !!props?.initialValues?.artwork || props?.initialValues?.designTemplates?.length > 0
        ? ['MULTIPAGEPDF']
        : props?.initialValues?.artworkSlots,
    quantitySettings: {
      ...(props.initialValues || {})?.quantitySettings,
      min: props.initialValues?.quantitySettings?.min || 1,
    },
    isShippable: props.initialValues?.isShippable ?? true,
  },
  category: selector(state, 'category'),
  quantities: selector(state, 'quantities'),
  isArtworkRequired: selector(state, 'isArtworkRequired'),
  designTemplates: selector(state, 'designTemplates'),
  isShippable: selector(state, 'isShippable'),
  isDesignEnabled: selector(state, 'isDesignEnabled'),
  isAdditionalPerPieceEnabled: selector(state, 'isAdditionalPerPieceEnabled'),
  isNameStatic: selector(state, 'isNameStatic'),
  artwork: selector(state, 'artwork'),
  tags: selector(state, 'tags'),
  images: selector(state, 'images'),
  // customFieldsSchema: selector(state, 'customFieldsSchema'),
  isComposite: selector(state, 'isComposite'),
  compositeProducts: selector(state, 'compositeProducts'),
  hasVariants: selector(state, 'hasVariants'),
  quantitySettings: selector(state, 'quantitySettings'),
  startAt: selector(state, 'startAt'),
  expireAt: selector(state, 'expireAt'),
  canOrderDigital: selector(state, 'canOrderDigital'),
});

const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => dispatch(change(formName, field, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(reduxedForm) as any;
