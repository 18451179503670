import * as React from 'react';
import {
  Button,
  Dimmer,
  Input,
  Form,
  Segment,
  Label,
  Header,
  Message,
  Icon,
  Image,
  Grid,
  Table,
  Popup,
} from 'semantic-ui-react';
import { Formik, Form as FormikForm, Field, FieldArray, FieldProps, ErrorMessage } from 'formik';
import ReactJson from 'react-json-view';
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/monokai';
// force-deploy here 2222222
import * as Yup from 'yup';
import { FeathersDropdown } from '@inkcloud/components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SemanticField } from '../../../common/Form';
import { slugify2 as slugifyKey } from '../../../common';
import { PackageUpload } from './PackageUpload';
import { FieldSidebar } from './FieldSidebar';

import Feathers from '../../../bootstrap/feathers';

import { Card } from '../../../common/Card';

interface DesignTemplateFormProps {
  id?: string;
  errorMessage?: string;
  initialValues: any;
  history?: any;
  onSubmit: Function;
  isSubmitting?: boolean;
}

const optionsType = [
  { value: 'text', text: 'Text' },
  { value: 'textarea', text: 'Text Area' },
  { value: 'hidden', text: 'Hidden' },
  { value: 'select', text: 'Select' },
  { value: 'multi-select', text: 'Multi Select' },
  { value: 'list', text: 'List' },
  { value: 'image', text: 'Image' },
  { value: 'masked-input', text: 'Masked Input' },
  { value: 'qrcode', text: 'QR Code' },
  { value: 'dynamic-date', text: 'Dynamic Date' },
  { value: 'conditional', text: 'Conditional' },
];

const loadProducts = async (id: string) =>
  Feathers.service('/products-legacy').find({
    query: {
      designTemplates: { $in: [id] },
      $select: ['key', 'staticName', 'designTemplates', 'inventory'],
    },
  });

const { useState, useEffect } = React;

export const DesignTemplateForm: React.FC<DesignTemplateFormProps> = (props) => {
  const { initialValues, id, errorMessage, history } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageOptionsModalOpen, setIsImageOptionsModalOpen] = useState(false);
  const [openFieldIndex, setOpenFieldIndex] = useState<number>();

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState({
    product: false,
  });

  const [selectedFieldIndex, setSelectedFieldIndex] = useState<number>();
  const [isFieldSidebarOpen, setIsFieldSidebarOpen] = useState<boolean>(false);

  const CustomerContactSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    key: Yup.string().required('Required'),
    fields: Yup.array().of(
      Yup.object().shape({
        key: Yup.string().required('Required'),
        label: Yup.string().required('Required'),
        maxChars: Yup.number()
          .typeError('Must be a number')
          .positive()
          .moreThan(0, 'Must be greater than 0'),
      })
    ),
  });

  const [isUploading, setIsUploading] = useState(false);
  const [tags, setTags] = useState<{ value: string; text: string; key: string }[]>([]);

  const [prevImage, setPrevImage] = useState('');

  const handleUploading = (v: boolean) => {
    setIsUploading(v);
  };

  const handleAddition = (e, data: any) => {
    const foundTag = tags.find((tag) => tag.value === data.value);

    if (!foundTag) {
      setTags([...tags, { text: data.value, value: data.value, key: data.value }]);
    }
  };

  const handleRemoveProductFromDesignTemplate = async (product: any) => {
    setIsLoading({
      ...isLoading,
      product: true,
    });

    const filteredTemplate = product?.designTemplates?.filter((dt) => dt !== id);

    try {
      const res: any = await Feathers.service('/products-legacy').patch(
        product?._id,
        {
          designTemplates: filteredTemplate,
        },
        { query: {} }
      );
    } catch (e) {
      console.log('e');
    }

    setSelectedProduct(null);
    setIsLoading({
      ...isLoading,
      product: false,
    });
  };

  useEffect(() => {
    if (id) {
      const loadPrevImage = async () => {
        const result: any = await Feathers.service('/design-templates').get(id, {
          query: { $populate: ['previews'] },
        });

        if (result.previews && result.previews.length) {
          setPrevImage(result.previews[0]);
        }
      };
      try {
        loadPrevImage();
      } catch (e) {
        console.error(e);
      }

      (async () => {
        try {
          if (id) {
            const res: any = await loadProducts(id);
            setProducts(res?.data);
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          const res: any = await loadProducts(id);
          setProducts(res?.data);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [isLoading]);

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={CustomerContactSchema}
        onSubmit={(values, { setSubmitting }) => {
          props.onSubmit(values);
        }}
      >
        {(innerProps) => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          } = innerProps;

          if (!values.previews) {
            values.previews = [];
          }

          const setOpen = (index: number) => {
            setOpenFieldIndex(index);
            // setOpenField({index, field});
            setIsModalOpen(true);
          };

          const handleAdditionValue = (data: any, fieldRow: any, fieldName: string) => {
            const foundField = fieldRow?.find((f) => f === data.value);

            if (!foundField) {
              setFieldValue(fieldName, [...(fieldRow || []), data.value]);
            }
          };

          const handleSlugify = (v) => setFieldValue('key', slugifyKey(v));

          return (
            <FormikForm
              className={`ui form small ${isSubmitting && !errorMessage && 'loading'} ${
                errorMessage ? 'error' : ''
              }`}
            >
              <Segment secondary>
                <Form.Field>
                  <label>Defintion Type</label>
                  <Form.Group inline>
                    <Form.Radio
                      label="General"
                      name="isMicrositeSpecific"
                      // value={'true'}
                      checked={!values.isMicrositeSpecific}
                      onBlur={handleBlur}
                      onChange={() => {
                        setFieldValue('isMicrositeSpecific', false);
                        setFieldValue('microsite', undefined);
                      }}
                      // disabled={values?._id}
                    />
                    <Form.Radio
                      label="Microsite Specific"
                      name="isMicrositeSpecific"
                      // value={'false'}
                      checked={values.isMicrositeSpecific || values.microsite !== undefined}
                      onBlur={handleBlur}
                      onChange={() => setFieldValue('isMicrositeSpecific', true)}
                      // disabled={values?._id}
                    />
                  </Form.Group>
                  {(values.isMicrositeSpecific || values.microsite !== undefined) && (
                    <Form.Field>
                      <Field
                        name="microsite"
                        render={({ field }: FieldProps<any>) => (
                          <>
                            {/* <label>Microsite</label> */}
                            <FeathersDropdown
                              search
                              selection
                              fluid
                              clearable
                              placeholder="Select Microsite..."
                              serviceName="/microsites"
                              // disabled={values?._id}

                              query={{
                                $np: 1,
                                $sort: { name: 1 },
                                $select: ['_id', 'name'],
                              }}
                              resultMapper={(r) => ({ value: r._id, text: r.name })}
                              {...field}
                              onChange={(e, data) =>
                                field.onChange({
                                  target: { value: data.value, name: 'microsite' },
                                } as any)
                              }
                            />
                          </>
                        )}
                      />
                    </Form.Field>
                  )}
                </Form.Field>
              </Segment>

              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField
                    name={'name'}
                    label="Name"
                    component={Form.Input}
                    onChange={(v) => {
                      if (!values?._id) {
                        setFieldValue('key', slugifyKey(v));
                      }
                    }}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="name"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name={'key'}
                    label="Key"
                    component={Form.Input}
                    onChange={handleSlugify}
                  />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="key"
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field>
                  <SemanticField name={'description'} label="Description" component={Form.Input} />
                  <ErrorMessage
                    render={(msg) => <Message negative size="tiny" content={msg} />}
                    name="description"
                  />
                </Form.Field>
                <Form.Field>
                  <SemanticField
                    name={'tags'}
                    label="Tags"
                    component={Form.Dropdown}
                    selection
                    search
                    clearable
                    multiple
                    allowAdditions
                    options={
                      values.tags
                        ? values.tags.map((tag: string) => ({ text: tag, value: tag, key: tag }))
                        : []
                    }
                    onAddItem={handleAddition}
                    noResultsMessage="Not found"
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <SemanticField
                  name={`ext`}
                  label="InDesign File version to use. IDML is recommended"
                  component={Form.Dropdown}
                  selection
                  search
                  options={[
                    { value: 'idml', text: 'IDML' },
                    { value: 'indd', text: 'INDD' },
                  ]}
                />
              </Form.Field>

              <PackageUpload
                multiple={false}
                buttonLabel={'Upload InDesign Package'}
                endpoint={'design-templates/templates-upload'}
                onUpload={(files) => {
                  // files.files[0].uploadLog
                  setFieldValue('templateUpload', files.files[0].uploadLog);
                }}
                onUploading={handleUploading}
              />

              <FieldArray
                name="previews"
                render={(arrayHelpers) => (
                  <div>
                    {values?.previews?.length > 0 && (
                      <Image.Group size="small">
                        {prevImage && (
                          <Image
                            bordered
                            src={`https://storage.googleapis.com/icstorage/${
                              (prevImage as any).cloudFilename
                            }`}
                            label={{
                              as: 'a',
                              color: 'grey',
                              corner: 'right',
                              icon: 'delete',
                              onClick: () => {
                                arrayHelpers.remove(0);
                              },
                            }}
                          />
                        )}
                      </Image.Group>
                    )}

                    <PackageUpload
                      multiple={false}
                      buttonLabel={'Upload Preview Image'}
                      endpoint={'design-templates/preview-upload'}
                      onUpload={(files) => {
                        setPrevImage(files.files[0]);

                        if (values?.previews?.length > 0) {
                          arrayHelpers.replace(0, files.files[0].uploadLog);
                        } else {
                          arrayHelpers.push(files.files[0].uploadLog);
                        }
                      }}
                      onUploading={handleUploading}
                    />
                  </div>
                )}
              />

              {id && (
                <>
                  <Header as="h4" content="Products" attached="top" />
                  <Segment attached="bottom">
                    <Table compact>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>SKU</Table.HeaderCell>
                          <Table.HeaderCell>Name</Table.HeaderCell>
                          <Table.HeaderCell />
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {products?.map((product: any, index: number) => (
                          <Table.Row key={index}>
                            <Table.Cell>{product?.key}</Table.Cell>
                            <Table.Cell>{product?.staticName ?? ''}</Table.Cell>
                            <Table.Cell textAlign="right">
                              <Popup
                                trigger={
                                  <Button
                                    type="button"
                                    size="tiny"
                                    icon="external"
                                    onClick={() => {
                                      history.push(
                                        `/products/${
                                          product?.inventory ? 'stock-products/edit/' : 'products/'
                                        }${product?._id}`
                                      );
                                    }}
                                  />
                                }
                                content="View Product"
                              />
                              <Popup
                                trigger={
                                  <Button
                                    loading={product._id === selectedProduct}
                                    disabled={product._id === selectedProduct}
                                    type="button"
                                    size="tiny"
                                    icon="trash"
                                    onClick={() => {
                                      setSelectedProduct(product._id);
                                      handleRemoveProductFromDesignTemplate(product);
                                    }}
                                  />
                                }
                                content="Remove"
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Segment>
                </>
              )}

              <Header as="h4" content="Fields" attached="top" />
              <Segment attached="bottom">
                <DndProvider backend={HTML5Backend}>
                  <FieldArray
                    name="fields"
                    render={(arrayHelpers) => (
                      <div>
                        <Grid>
                          <div className="column" style={{ width: '40px' }}></div>
                          <Grid.Column width={4}>
                            <strong>Key</strong>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <strong>Label</strong>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <strong>Type</strong>
                          </Grid.Column>
                          <Grid.Column width={4} />
                        </Grid>
                        {values?.fields?.map((field: any, index: number) => (
                          <Card
                            key={field._id}
                            index={index}
                            id={field._id}
                            move={(a, b) => arrayHelpers.move(a, b)}
                          >
                            <Grid key={index} stackable>
                              <div className="column icon-wrapper" style={{ width: '40px' }}>
                                <Icon name="arrows alternate vertical" />
                              </div>
                              <Grid.Column width={4}>
                                {field.key}
                                {errors?.fields?.[index]?.key && (
                                  <Message
                                    negative
                                    size="tiny"
                                    content={errors?.fields?.[index]?.key}
                                  />
                                )}
                              </Grid.Column>
                              <Grid.Column width={4}>
                                {field.label}
                                {errors?.fields?.[index]?.label && (
                                  <Message
                                    negative
                                    size="tiny"
                                    content={errors?.fields?.[index]?.label}
                                  />
                                )}
                              </Grid.Column>
                              <Grid.Column width={4}>
                                {optionsType?.find((o) => o.value === field.type)?.text}
                              </Grid.Column>
                              <Grid.Column width={3} textAlign="right">
                                <Button
                                  type="button"
                                  icon="edit"
                                  onClick={() => {
                                    setSelectedFieldIndex(index);
                                    setIsFieldSidebarOpen(true);
                                  }}
                                />
                                <Button
                                  type="button"
                                  icon="remove"
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </Grid.Column>
                            </Grid>
                          </Card>
                        ))}

                        <div style={{ marginTop: '1rem' }}>
                          <Button
                            type="button"
                            size="tiny"
                            content="Add Field"
                            style={{ marginLeft: '30px' }}
                            onClick={() => {
                              setSelectedFieldIndex(values?.fields?.length);
                              setIsFieldSidebarOpen(true);
                              arrayHelpers.push({
                                key: '',
                                label: '',
                                type: 'text',
                              });
                            }}
                          />
                        </div>
                      </div>
                    )}
                  />
                </DndProvider>
              </Segment>

              <Segment secondary>
                <Grid divided="vertically">
                  <Grid.Row>
                    <Grid.Column width="3">Copy Field to Order</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        fluid
                        selection
                        search
                        clearable
                        name={`carryFieldToRef`}
                        placeholder="Select a field..."
                        component={Form.Dropdown}
                        options={values?.fields?.reduce(
                          (acc, cur) =>
                            cur?.key && cur?.label && cur.type !== 'hidden'
                              ? acc?.push({ value: cur.key, text: cur.label }) && acc
                              : acc,
                          []
                        )}
                      />
                      <span>
                        If you select a field, the value of the chosen field will carry on to the
                        order item's customer reference name
                      </span>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width="3">Crop Marks</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'finalSettings.enableCropMarks'}
                        label="Enable Crop Marks"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width="3">Spot UV</Grid.Column>
                    <Grid.Column width="13">
                      <SemanticField
                        component={Form.Checkbox}
                        toggle
                        name={'isSpotUv'}
                        label="Is Spot UV"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>

              {errorMessage && <Message error content={errorMessage} />}

              <Button
                primary
                type="submit"
                disabled={(isSubmitting && !errorMessage) || isUploading}
              >
                Save
              </Button>
              <Dimmer
                page={true}
                inverted
                active={isFieldSidebarOpen}
                // onClickOutside={() => {
                //   setIsFieldSidebarOpen(false);
                //   setSelectedFieldIndex(null);
                // }}
              >
                {selectedFieldIndex !== null && (
                  <FieldSidebar
                    visible={isFieldSidebarOpen}
                    field={values.fields[selectedFieldIndex as number]}
                    fields={values.fields}
                    errors={errors}
                    index={selectedFieldIndex as number}
                    onClose={() => {
                      setIsFieldSidebarOpen(false);
                      setSelectedFieldIndex(undefined);
                    }}
                  />
                )}
              </Dimmer>
            </FormikForm>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};
